var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { IconButton } from 'UI';
import FunnelSaveModal from 'App/components/Funnels/FunnelSaveModal';
import { connect } from 'react-redux';
import { init } from 'Duck/funnels';
function SaveFunnelButton(props) {
    var _a = __read(useState(false), 2), showModal = _a[0], setshowModal = _a[1];
    var handleClick = function () {
        props.init({ filter: props.filter });
        setshowModal(true);
    };
    return (React.createElement("div", null,
        React.createElement(IconButton, { className: "mr-2", onClick: handleClick, primaryText: true, label: "SAVE FUNNEL", icon: "funnel" }),
        React.createElement(FunnelSaveModal, { show: showModal, closeHandler: function () { return setshowModal(false); } })));
}
export default connect(function (state) { return ({
    filter: state.getIn(['search', 'instance']),
}); }, { init: init })(SaveFunnelButton);
