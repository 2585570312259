import React from 'react';
import stl from './DropdownPlain.css';
import { Dropdown, Icon } from 'UI';
export default function DropdownPlain(props) {
    var _a = props.name, name = _a === void 0 ? "sort" : _a, value = props.value, options = props.options, _b = props.icon, icon = _b === void 0 ? "chevron-down" : _b, _c = props.direction, direction = _c === void 0 ? "right" : _c, _d = props.multiple, multiple = _d === void 0 ? false : _d;
    return (React.createElement("div", null,
        React.createElement(Dropdown, { value: value, name: name, className: stl.dropdown, direction: direction, options: options, onChange: props.onChange, 
            // floating
            scrolling: true, multiple: multiple, selectOnBlur: false, 
            // defaultValue={ value }
            icon: icon ? React.createElement(Icon, { name: "chevron-down", color: "gray-dark", size: "14", className: stl.dropdownIcon }) : null })));
}
