import React from 'react';
import { Icon, Link } from 'UI';
import stl from './roleItem.css';
import cn from 'classnames';
import { CLIENT_TABS, client as clientRoute } from 'App/routes';
function PermisionLabel(_a) {
    var label = _a.label;
    return (React.createElement("div", { className: cn(stl.label, 'mb-2') }, label));
}
function PermisionLabelLinked(_a) {
    var label = _a.label, route = _a.route;
    return (React.createElement(Link, { to: route },
        React.createElement("div", { className: cn(stl.label, 'mb-2 bg-active-blue color-teal') }, label)));
}
function RoleItem(_a) {
    var _b;
    var role = _a.role, deleteHandler = _a.deleteHandler, editHandler = _a.editHandler, isAdmin = _a.isAdmin, permissions = _a.permissions, projects = _a.projects;
    return (React.createElement("div", { className: cn('flex items-start relative py-4 hover border-b px-3 pr-20') },
        React.createElement("div", { className: "flex", style: { width: '20%' } },
            React.createElement(Icon, { name: "user-alt", size: "16", marginRight: "10" }),
            role.name),
        React.createElement("div", { className: "flex items-start flex-wrap", style: { width: '30%' } }, role.allProjects ? (React.createElement(PermisionLabelLinked, { label: "All projects", route: clientRoute(CLIENT_TABS.SITES) })) : (role.projects.map(function (p) { return (React.createElement(PermisionLabel, { label: projects[p] })); }))),
        React.createElement("div", { className: "flex items-start flex-wrap", style: { width: '50%' } }, role.permissions.map(function (permission) { return (React.createElement(PermisionLabel, { label: permissions[permission], key: permission.id })); })),
        isAdmin && (React.createElement("div", { className: cn(stl.actions, 'absolute right-0 top-0 bottom-0 mr-8') }, !!editHandler &&
            React.createElement("div", { className: cn(stl.button, (_b = {}, _b[stl.disabled] = role.protected, _b)), onClick: function () { return editHandler(role); } },
                React.createElement(Icon, { name: "edit", size: "16", color: "teal" }))))));
}
export default RoleItem;
