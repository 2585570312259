var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { FilterType } from 'App/types/filter/filterType';
import React, { useState, useEffect } from 'react';
import stl from './FilterSource.css';
function FilterSource(props) {
    var filter = props.filter;
    var _a = __read(useState(filter.source[0] || ''), 2), value = _a[0], setValue = _a[1];
    var onChange = function (_a) {
        var _b;
        var _c = _a.target, value = _c.value, name = _c.name;
        props.onUpdate(__assign(__assign({}, filter), (_b = {}, _b[name] = [value], _b)));
    };
    useEffect(function () {
        setValue(filter.source[0] || '');
    }, [filter]);
    useEffect(function () {
        props.onUpdate(__assign(__assign({}, filter), { source: [value] }));
    }, [value]);
    var write = function (_a) {
        var _b = _a.target, value = _b.value, name = _b.name;
        return setValue(value);
    };
    var renderFiled = function () {
        switch (filter.sourceType) {
            case FilterType.NUMBER:
                return (React.createElement("input", { name: "source", className: stl.inputField, value: value, onBlur: write, onChange: write, type: "number" }));
        }
    };
    return (React.createElement("div", null, renderFiled()));
}
export default FilterSource;
