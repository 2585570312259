import React from 'react';
import { IconButton } from 'UI';
import { connect } from 'react-redux';
import { save } from 'Duck/funnels';
function UpdateFunnelButton(props) {
    var loading = props.loading;
    return (React.createElement("div", null,
        React.createElement(IconButton, { className: "mr-2", disabled: loading, onClick: function () { return props.save(); }, primaryText: true, label: "UPDATE FUNNEL", icon: "funnel" })));
}
export default connect(function (state) { return ({
    loading: state.getIn(['funnels', 'saveRequest', 'loading']) ||
        state.getIn(['funnels', 'updateRequest', 'loading']),
}); }, { save: save })(UpdateFunnelButton);
