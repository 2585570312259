var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { Popup, IconButton } from 'UI';
import { connect } from 'react-redux';
import cn from 'classnames';
import { toggleChatWindow } from 'Duck/sessions';
import { connectPlayer } from 'Player/store';
import ChatWindow from '../../ChatWindow';
import { callPeer, requestReleaseRemoteControl } from 'Player';
import { CallingState, ConnectionStatus, RemoteControlStatus } from 'Player/MessageDistributor/managers/AssistManager';
import RequestLocalStream from 'Player/MessageDistributor/managers/LocalStream';
import { toast } from 'react-toastify';
import { confirm } from 'UI/Confirmation';
import stl from './AassistActions.css';
function onClose(stream) {
    stream.getTracks().forEach(function (t) { return t.stop(); });
}
function onReject() {
    toast.info("Call was rejected.");
}
function onError(e) {
    toast.error(e);
}
function AssistActions(_a) {
    var _b, _c;
    var _this = this;
    var toggleChatWindow = _a.toggleChatWindow, userId = _a.userId, calling = _a.calling, peerConnectionStatus = _a.peerConnectionStatus, remoteControlStatus = _a.remoteControlStatus, hasPermission = _a.hasPermission, isEnterprise = _a.isEnterprise;
    var _d = __read(useState(null), 2), incomeStream = _d[0], setIncomeStream = _d[1];
    var _e = __read(useState(null), 2), localStream = _e[0], setLocalStream = _e[1];
    var _f = __read(useState(null), 2), callObject = _f[0], setCallObject = _f[1];
    useEffect(function () {
        return callObject === null || callObject === void 0 ? void 0 : callObject.end();
    }, []);
    useEffect(function () {
        if (peerConnectionStatus == ConnectionStatus.Disconnected) {
            toast.info("Live session was closed.");
        }
    }, [peerConnectionStatus]);
    function call() {
        RequestLocalStream().then(function (lStream) {
            setLocalStream(lStream);
            setCallObject(callPeer(lStream, setIncomeStream, lStream.stop.bind(lStream), onReject, onError));
        }).catch(onError);
    }
    var confirmCall = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, confirm({
                        header: 'Start Call',
                        confirmButton: 'Call',
                        confirmation: "Are you sure you want to call " + (userId ? userId : 'User') + "?"
                    })];
                case 1:
                    if (_a.sent()) {
                        call();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var onCall = calling === CallingState.OnCall || calling === CallingState.Reconnecting;
    var cannotCall = (peerConnectionStatus !== ConnectionStatus.Connected) || (isEnterprise && !hasPermission);
    var remoteActive = remoteControlStatus === RemoteControlStatus.Enabled;
    return (React.createElement("div", { className: "flex items-center" },
        React.createElement("div", { className: cn('cursor-pointer p-2 flex items-center', (_b = {}, _b[stl.disabled] = cannotCall, _b)), onClick: requestReleaseRemoteControl, role: "button" },
            React.createElement(IconButton, { label: (remoteActive ? 'Stop ' : '') + " Remote Control", icon: "remote-control", primaryText: true, redText: remoteActive })),
        React.createElement(Popup, { trigger: React.createElement("div", { className: cn('cursor-pointer p-2 flex items-center', (_c = {}, _c[stl.disabled] = cannotCall, _c)), onClick: onCall ? callObject === null || callObject === void 0 ? void 0 : callObject.end : confirmCall, role: "button" },
                React.createElement(IconButton, { size: "small", primary: !onCall, red: onCall, label: onCall ? 'End' : 'Call', icon: "headset" })), content: cannotCall ? "You don’t have the permissions to perform this action." : "Call " + (userId ? userId : 'User'), size: "tiny", inverted: true, position: "top right" }),
        React.createElement("div", { className: "fixed ml-3 left-0 top-0", style: { zIndex: 999 } }, onCall && callObject && React.createElement(ChatWindow, { endCall: callObject.end, userId: userId, incomeStream: incomeStream, localStream: localStream }))));
}
var con = connect(function (state) {
    var permissions = state.getIn(['user', 'account', 'permissions']) || [];
    return {
        hasPermission: permissions.includes('ASSIST_CALL'),
        isEnterprise: state.getIn(['user', 'client', 'edition']) === 'ee',
    };
}, { toggleChatWindow: toggleChatWindow });
export default con(connectPlayer(function (state) { return ({
    calling: state.calling,
    remoteControlStatus: state.remoteControl,
    peerConnectionStatus: state.peerConnectionStatus,
}); })(AssistActions));
