var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import FilterList from 'Shared/Filters/FilterList';
import FilterSelection from 'Shared/Filters/FilterSelection';
import { connect } from 'react-redux';
import { IconButton } from 'UI';
import { editFilter, addFilter } from 'Duck/funnels';
import UpdateFunnelButton from 'Shared/UpdateFunnelButton';
function FunnelSearch(props) {
    var appliedFilter = props.appliedFilter;
    var hasEvents = appliedFilter.filters.filter(function (i) { return i.isEvent; }).size > 0;
    var hasFilters = appliedFilter.filters.filter(function (i) { return !i.isEvent; }).size > 0;
    var onAddFilter = function (filter) {
        props.addFilter(filter);
    };
    var onUpdateFilter = function (filterIndex, filter) {
        var newFilters = appliedFilter.filters.map(function (_filter, i) {
            if (i === filterIndex) {
                return filter;
            }
            else {
                return _filter;
            }
        });
        props.editFilter(__assign(__assign({}, appliedFilter), { filters: newFilters }));
    };
    var onRemoveFilter = function (filterIndex) {
        var newFilters = appliedFilter.filters.filter(function (_filter, i) {
            return i !== filterIndex;
        });
        props.editFilter({
            filters: newFilters,
        });
    };
    var onChangeEventsOrder = function (e, _a) {
        var name = _a.name, value = _a.value;
        props.editFilter({
            eventsOrder: value,
        });
    };
    return (React.createElement("div", { className: "border bg-white rounded mt-4" },
        React.createElement("div", { className: "p-5" },
            React.createElement(FilterList, { filter: appliedFilter, onUpdateFilter: onUpdateFilter, onRemoveFilter: onRemoveFilter, onChangeEventsOrder: onChangeEventsOrder, hideEventsOrder: true })),
        React.createElement("div", { className: "border-t px-5 py-1 flex items-center -mx-2" },
            React.createElement("div", null,
                React.createElement(FilterSelection, { filter: undefined, onFilterClick: onAddFilter },
                    React.createElement(IconButton, { primaryText: true, label: "ADD STEP", icon: "plus" }))),
            React.createElement("div", { className: "ml-auto flex items-center" },
                React.createElement(UpdateFunnelButton, null)))));
}
export default connect(function (state) { return ({
    appliedFilter: state.getIn(['funnels', 'instance', 'filter']),
}); }, { editFilter: editFilter, addFilter: addFilter })(FunnelSearch);
