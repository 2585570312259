var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { SlideModal, Avatar, TextEllipsis } from 'UI';
import SessionList from '../SessionList';
import stl from './assistTabs.css';
var AssistTabs = function (props) {
    var _a = __read(useState(false), 2), showMenu = _a[0], setShowMenu = _a[1];
    return (React.createElement("div", { className: "relative mr-4" },
        React.createElement("div", { className: "flex items-center" }, props.userId && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "flex items-center mr-3" },
                React.createElement(Avatar, { iconSize: "20", width: "30px", height: "30px", seed: props.userNumericHash }),
                React.createElement("div", { className: "ml-2 font-medium" },
                    React.createElement(TextEllipsis, { maxWidth: 120, inverted: true, popupProps: { inverted: true, size: 'tiny' } },
                        props.userId,
                        "'s"))),
            React.createElement("div", { className: stl.btnLink, onClick: function () { return setShowMenu(!showMenu); } }, "Active Sessions")))),
        React.createElement(SlideModal, { title: React.createElement("div", null,
                props.userId,
                "'s ",
                React.createElement("span", { className: "color-gray-medium" }, "Live Sessions"),
                " "), isDisplayed: showMenu, content: showMenu && React.createElement(SessionList, null), onClose: function () { return setShowMenu(false); } })));
};
export default AssistTabs;
