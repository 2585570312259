var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { Button, Icon } from 'UI';
import SavedSearchDropdown from './components/SavedSearchDropdown';
import { connect } from 'react-redux';
import { fetchList as fetchListSavedSearch } from 'Duck/search';
import OutsideClickDetectingDiv from 'Shared/OutsideClickDetectingDiv';
import cn from 'classnames';
import stl from './SavedSearch.css';
function SavedSearch(props) {
    var _a;
    var list = props.list;
    var savedSearch = props.savedSearch;
    var _b = __read(useState(false), 2), showMenu = _b[0], setShowMenu = _b[1];
    useEffect(function () {
        props.fetchListSavedSearch();
    }, []);
    return (React.createElement(OutsideClickDetectingDiv
    // className={ cn("relative", { "flex-1" : fullWidth }) } 
    , { 
        // className={ cn("relative", { "flex-1" : fullWidth }) } 
        onClickOutside: function () { return setShowMenu(false); } },
        React.createElement("div", { className: "relative" },
            React.createElement("div", { className: cn("flex items-center", (_a = {}, _a[stl.disabled] = list.size === 0, _a)) },
                React.createElement(Button, { outline: true, size: "small", className: "flex items-center", onClick: function () { return setShowMenu(true); } },
                    React.createElement("span", { className: "mr-2" }, "Saved Search (" + list.size + ")"),
                    React.createElement(Icon, { name: "ellipsis-v", color: "teal", size: "14" })),
                savedSearch.exists() && (React.createElement("div", { className: "flex items-center ml-2" },
                    React.createElement(Icon, { name: "search", size: "14" }),
                    React.createElement("span", { className: "color-gray-medium px-1" }, "Viewing:"),
                    React.createElement("span", { className: "font-medium", style: { whiteSpace: 'nowrap', width: '30%' } }, savedSearch.name)))),
            showMenu && (React.createElement("div", { className: "absolute left-0 bg-white border rounded z-50", style: { top: '33px', width: '200px' } },
                React.createElement(SavedSearchDropdown, { list: list, onClose: function () { return setShowMenu(false); } }))))));
}
export default connect(function (state) { return ({
    list: state.getIn(['search', 'list']),
    savedSearch: state.getIn(['search', 'savedSearch'])
}); }, { fetchListSavedSearch: fetchListSavedSearch })(SavedSearch);
