import React from 'react';
import { Icon, Popup } from 'UI';
import cn from 'classnames';
export default React.memo(function SortOrderButton(props) {
    var sortOrder = props.sortOrder, _a = props.onChange, onChange = _a === void 0 ? function () { return null; } : _a;
    var isAscending = sortOrder === 'asc';
    return (React.createElement("div", { className: "flex items-center border" },
        React.createElement(Popup, { inverted: true, size: "mini", trigger: React.createElement("div", { className: cn("p-1 hover:bg-active-blue", { 'cursor-pointer bg-white': !isAscending, 'bg-active-blue': isAscending }), onClick: function () { return onChange('asc'); } },
                React.createElement(Icon, { name: "arrow-up", size: "14", color: isAscending ? 'teal' : 'gray-medium' })), content: 'Ascending' }),
        React.createElement(Popup, { inverted: true, size: "mini", trigger: React.createElement("div", { className: cn("p-1 hover:bg-active-blue border-l", { 'cursor-pointer bg-white': isAscending, 'bg-active-blue': !isAscending }), onClick: function () { return onChange('desc'); } },
                React.createElement(Icon, { name: "arrow-down", size: "14", color: !isAscending ? 'teal' : 'gray-medium' })), content: 'Descending' })));
});
