var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { save } from 'Duck/filters';
import { IconButton } from 'UI';
import SaveSearchModal from 'Shared/SaveSearchModal';
function SaveFilterButton(props) {
    var savedSearch = props.savedSearch;
    var _a = __read(useState(false), 2), showModal = _a[0], setshowModal = _a[1];
    return (React.createElement("div", null,
        savedSearch.exists() ? (React.createElement(IconButton, { className: "mr-2", onClick: function () { return setshowModal(true); }, primaryText: true, label: "UPDATE SEARCH", icon: "zoom-in" })) : (React.createElement(IconButton, { className: "mr-2", onClick: function () { return setshowModal(true); }, primaryText: true, label: "SAVE SEARCH", icon: "zoom-in" })),
        showModal && (React.createElement(SaveSearchModal, { show: showModal, closeHandler: function () { return setshowModal(false); } }))));
}
export default connect(function (state) { return ({
    filter: state.getIn(['search', 'instance']),
    savedSearch: state.getIn(['search', 'savedSearch']),
}); }, { save: save })(SaveFilterButton);
