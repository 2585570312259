import React from 'react';
import cn from 'classnames';
import { Dropdown, Icon } from 'UI';
import stl from './FilterValueDropdown.css';
function FilterValueDropdown(props) {
    var filter = props.filter, _a = props.multiple, multiple = _a === void 0 ? false : _a, _b = props.isMultilple, isMultilple = _b === void 0 ? true : _b, _c = props.search, search = _c === void 0 ? false : _c, options = props.options, onChange = props.onChange, value = props.value, _d = props.className, className = _d === void 0 ? '' : _d, _e = props.showCloseButton, showCloseButton = _e === void 0 ? true : _e, _f = props.showOrButton, showOrButton = _f === void 0 ? true : _f;
    // const options = []
    return (React.createElement("div", { className: "relative flex items-center w-full" },
        React.createElement("div", { className: stl.wrapper },
            React.createElement(Dropdown, { search: search, className: cn(stl.operatorDropdown, className, "filterDropdown"), options: options, name: "issue_type", value: value, onChange: onChange, placeholder: "Select", fluid: true, icon: React.createElement(Icon, { className: "absolute right-0 mr-2", name: "chevron-down", size: "12" }) }),
            React.createElement("div", { className: stl.right },
                showCloseButton && React.createElement("div", { onClick: props.onRemoveValue },
                    React.createElement(Icon, { name: "close", size: "12" })),
                showOrButton && React.createElement("div", { onClick: props.onAddValue, className: "color-teal" },
                    React.createElement("span", { className: "px-1" }, "or")))),
        !showOrButton && isMultilple && React.createElement("div", { className: "ml-3" }, "or")));
}
export default FilterValueDropdown;
