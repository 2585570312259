import React from 'react';
import { Popup } from 'UI';
import MetaItem from '../MetaItem';
export default function MetaMoreButton(props) {
    var list = props.list, maxLength = props.maxLength;
    return (React.createElement(Popup, { trigger: (React.createElement("div", { className: "flex items-center" },
            React.createElement("span", { className: "rounded bg-active-blue color-teal px-2 color-gray-dark cursor-pointer" },
                "+",
                list.length - maxLength,
                " More"))), className: "p-0", content: React.createElement("div", { className: "text-sm grid grid-col p-4 gap-3", style: { maxHeight: '200px', overflowY: 'auto' } }, list.slice(maxLength).map(function (_a, index) {
            var label = _a.label, value = _a.value;
            return (React.createElement(MetaItem, { key: index, label: label, value: value }));
        })), on: "click", position: "center center" }));
}
