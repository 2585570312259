var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { IconButton } from 'UI';
import copy from 'copy-to-clipboard';
import { connectPlayer } from 'Player';
function SessionCopyLink(_a) {
    var _b = _a.content, content = _b === void 0 ? '' : _b, time = _a.time;
    var _c = __read(React.useState(false), 2), copied = _c[0], setCopied = _c[1];
    var copyHandler = function () {
        setCopied(true);
        copy(window.location.origin + window.location.pathname + '?jumpto=' + Math.round(time));
        setTimeout(function () {
            setCopied(false);
        }, 1000);
    };
    return (React.createElement("div", { className: "flex justify-between items-center w-full mt-2" },
        React.createElement(IconButton, { label: "Copy Link", primaryText: true, icon: "link-45deg", onClick: copyHandler }),
        copied && React.createElement("div", { className: "color-teal" }, "Copied to Clipboard")));
}
export default connectPlayer(function (state) { return ({
    time: state.time,
}); })(SessionCopyLink);
