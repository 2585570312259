var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import stl from './SavedSearchDropdown.css';
import cn from 'classnames';
import { Icon } from 'UI';
import { applySavedSearch, remove, edit } from 'Duck/search';
import { connect } from 'react-redux';
import { confirm } from 'UI/Confirmation';
function Row(_a) {
    var name = _a.name, isPublic = _a.isPublic, onClick = _a.onClick, onClickEdit = _a.onClickEdit, onDelete = _a.onDelete;
    return (React.createElement("div", { onClick: onClick, className: cn(stl.rowItem, "flex items-center cursor-pointer hover:bg-active-blue") },
        React.createElement("div", { className: "px-3 py-2" }, name),
        React.createElement("div", { className: "ml-auto flex items-center" }, isPublic && React.createElement("div", { className: "cursor-pointer px-2 hover:bg-active-blue" },
            React.createElement(Icon, { name: "user-friends", size: "14" })))));
}
function SavedSearchDropdown(props) {
    var _this = this;
    var _a = __read(React.useState(''), 2), query = _a[0], setQuery = _a[1];
    var filteredList = query ? props.list.filter(function (item) { return item.name.toLowerCase().includes(query.toLowerCase()); }) : props.list;
    var onClick = function (item) {
        props.applySavedSearch(item);
        // props.edit(item.filter)
        props.onClose();
    };
    var onDelete = function (instance) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, confirm({
                        header: 'Confirm',
                        confirmButton: 'Yes, Delete',
                        confirmation: "Are you sure you want to permanently delete this search?"
                    })];
                case 1:
                    if (_a.sent()) {
                        props.remove(instance.alertId).then(function () {
                            // toggleForm(null, false);
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var onClickEdit = function (instance) {
        // toggleForm(instance);
    };
    return (React.createElement("div", { className: cn(stl.wrapper, 'shadow') },
        React.createElement("div", { className: "w-full border p-1" },
            React.createElement("input", { placeholder: 'Search', autoFocus: true, className: "border p-1 w-full rounded", style: { border: 'solid thin #ddd' }, onChange: function (e) { return setQuery(e.target.value); } })),
        filteredList.map(function (item) { return (React.createElement(Row, { key: item.searchId, name: item.name, onClick: function () { return onClick(item); }, onDelete: function () { return onDelete(item); }, onClickEdit: function () { return onClickEdit(item); }, isPublic: item.isPublic })); })));
}
export default connect(null, { applySavedSearch: applySavedSearch, remove: remove, edit: edit })(SavedSearchDropdown);
