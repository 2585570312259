var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Loader, NoContent, SegmentSelection } from 'UI';
import { Styles } from '../../common';
// import { ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, LineChart, Line, Legend } from 'recharts';
import Period, { LAST_24_HOURS, LAST_30_MINUTES, YESTERDAY, LAST_7_DAYS } from 'Types/app/period';
import stl from './CustomMetricWidgetPreview.css';
import { getChartFormatter } from 'Types/dashboard/helper';
import { remove } from 'Duck/customMetrics';
import DateRange from 'Shared/DateRange';
import { edit } from 'Duck/customMetrics';
import CustomMetriLineChart from '../CustomMetriLineChart';
import CustomMetricPercentage from '../CustomMetricPercentage';
import CustomMetricTable from '../CustomMetricTable';
import APIClient from 'App/api_client';
import CustomMetricPieChart from '../CustomMetricPieChart';
var customParams = function (rangeName) {
    var params = { density: 70 };
    if (rangeName === LAST_24_HOURS)
        params.density = 70;
    if (rangeName === LAST_30_MINUTES)
        params.density = 70;
    if (rangeName === YESTERDAY)
        params.density = 70;
    if (rangeName === LAST_7_DAYS)
        params.density = 70;
    return params;
};
function CustomMetricWidget(props) {
    var metric = props.metric, showSync = props.showSync;
    var _a = __read(useState(false), 2), loading = _a[0], setLoading = _a[1];
    var _b = __read(useState({ chart: [{}] }), 2), data = _b[0], setData = _b[1];
    var _c = __read(useState([]), 2), seriesMap = _c[0], setSeriesMap = _c[1];
    var _d = __read(useState(Period({ rangeName: metric.rangeName, startDate: metric.startDate, endDate: metric.endDate })), 2), period = _d[0], setPeriod = _d[1];
    var colors = Styles.customMetricColors;
    var params = customParams(period.rangeName);
    var gradientDef = Styles.gradientDef();
    var metricParams = __assign(__assign({}, params), { metricId: metric.metricId, viewType: 'lineChart' });
    var prevMetricRef = useRef();
    var isTimeSeries = metric.metricType === 'timeseries';
    var isTable = metric.metricType === 'table';
    useEffect(function () {
        // Check for title change
        if (prevMetricRef.current && prevMetricRef.current.name !== metric.name) {
            prevMetricRef.current = metric;
            return;
        }
        ;
        prevMetricRef.current = metric;
        setLoading(true);
        // fetch new data for the widget preview
        new APIClient()['post']('/custom_metrics/try', __assign(__assign({}, metricParams), metric.toSaveData()))
            .then(function (response) { return response.json(); })
            .then(function (_a) {
            var errors = _a.errors, data = _a.data;
            if (errors) {
                console.log('err', errors);
            }
            else {
                var namesMap = data
                    .map(function (i) { return Object.keys(i); })
                    .flat()
                    .filter(function (i) { return i !== 'time' && i !== 'timestamp'; })
                    .reduce(function (unique, item) {
                    if (!unique.includes(item)) {
                        unique.push(item);
                    }
                    return unique;
                }, []);
                setSeriesMap(namesMap);
                setData(getChartFormatter(period)(data));
            }
        }).finally(function () { return setLoading(false); });
    }, [metric]);
    var onDateChange = function (changedDates) {
        setPeriod(__assign(__assign({}, changedDates), { rangeName: changedDates.rangeValue }));
        props.edit(__assign(__assign({}, changedDates), { rangeName: changedDates.rangeValue }));
    };
    var chagneViewType = function (e, _a) {
        var _b;
        var name = _a.name, value = _a.value;
        props.edit((_b = {}, _b[name] = value, _b));
    };
    return (React.createElement("div", { className: "mb-10" },
        React.createElement("div", { className: "flex items-center" },
            React.createElement("div", { className: "mr-auto font-medium" }, "Preview"),
            React.createElement("div", { className: "flex items-center" },
                isTimeSeries && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "color-gray-medium mr-2" }, "Visualization"),
                    React.createElement(SegmentSelection, { name: "viewType", className: "my-3", primary: true, icons: true, onSelect: chagneViewType, value: { value: metric.viewType }, list: [
                            { value: 'lineChart', name: 'Chart', icon: 'graph-up-arrow' },
                            { value: 'progress', name: 'Progress', icon: 'hash' },
                        ] }))),
                isTable && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "mr-1 color-gray-medium" }, "Visualization"),
                    React.createElement(SegmentSelection, { name: "viewType", className: "my-3", primary: true, icons: true, onSelect: chagneViewType, value: { value: metric.viewType }, list: [
                            { value: 'table', name: 'Table', icon: 'table' },
                            { value: 'pieChart', name: 'Chart', icon: 'pie-chart-fill' },
                        ] }))),
                React.createElement("div", { className: "mx-4" }),
                React.createElement("span", { className: "mr-1 color-gray-medium" }, "Time Range"),
                React.createElement(DateRange, { rangeValue: metric.rangeName, startDate: metric.startDate, endDate: metric.endDate, onDateChange: onDateChange, customRangeRight: true, direction: "left" }))),
        React.createElement("div", { className: stl.wrapper },
            React.createElement("div", { className: stl.innerWapper },
                React.createElement(Loader, { loading: loading, size: "small" },
                    React.createElement(NoContent, { size: "small", show: data.length === 0 },
                        React.createElement("div", { className: "p-4 font-medium" }, metric.name),
                        React.createElement("div", { className: "px-4 pb-4" },
                            isTimeSeries && (React.createElement(React.Fragment, null,
                                metric.viewType === 'progress' && (React.createElement(CustomMetricPercentage, { data: data[0], colors: colors, params: params })),
                                metric.viewType === 'lineChart' && (React.createElement(CustomMetriLineChart, { data: data, seriesMap: seriesMap, colors: colors, params: params })))),
                            isTable && (React.createElement(React.Fragment, null, metric.viewType === 'table' ? (React.createElement(CustomMetricTable, { metric: metric, data: data[0] })) : (React.createElement(CustomMetricPieChart, { metric: metric, data: data[0], colors: colors, params: params })))))))))));
}
export default connect(null, { remove: remove, edit: edit })(CustomMetricWidget);
