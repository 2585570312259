var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Styles } from '../../common';
import { ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { LineChart, Line, Legend } from 'recharts';
function CustomMetriLineChart(props) {
    var data = props.data, params = props.params, seriesMap = props.seriesMap, colors = props.colors, _a = props.onClick, onClick = _a === void 0 ? function () { return null; } : _a;
    return (React.createElement(ResponsiveContainer, { height: 240, width: "100%" },
        React.createElement(LineChart, { data: data, margin: Styles.chartMargins, 
            // syncId={ showSync ? "domainsErrors_4xx" : undefined }
            onClick: onClick, isAnimationActive: false },
            React.createElement(CartesianGrid, { strokeDasharray: "3 3", vertical: false, stroke: "#EEEEEE" }),
            React.createElement(XAxis, __assign({}, Styles.xaxis, { dataKey: "time", interval: params.density / 7 })),
            React.createElement(YAxis, __assign({}, Styles.yaxis, { allowDecimals: false, label: __assign(__assign({}, Styles.axisLabelLeft), { value: "Number of Sessions" }) })),
            React.createElement(Legend, null),
            React.createElement(Tooltip, __assign({}, Styles.tooltip)),
            seriesMap.map(function (key, index) { return (React.createElement(Line, { key: key, name: key, type: "monotone", dataKey: key, stroke: colors[index], fillOpacity: 1, strokeWidth: 2, strokeOpacity: 0.6, 
                // fill="url(#colorCount)"
                dot: false })); }))));
}
export default CustomMetriLineChart;
