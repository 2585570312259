var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { Icon } from 'UI';
// import { debounce } from 'App/utils';
import stl from './FilterAutoCompleteLocal.css';
function FilterAutoCompleteLocal(props) {
    var _a = props.showCloseButton, showCloseButton = _a === void 0 ? false : _a, _b = props.placeholder, placeholder = _b === void 0 ? 'Enter' : _b, _c = props.showOrButton, showOrButton = _c === void 0 ? false : _c, _d = props.onRemoveValue, onRemoveValue = _d === void 0 ? function () { return null; } : _d, _e = props.onAddValue, onAddValue = _e === void 0 ? function () { return null; } : _e, _f = props.value, value = _f === void 0 ? '' : _f, _g = props.icon, icon = _g === void 0 ? null : _g, _h = props.type, type = _h === void 0 ? "text" : _h, _j = props.isMultilple, isMultilple = _j === void 0 ? true : _j;
    var _k = __read(useState(true), 2), showModal = _k[0], setShowModal = _k[1];
    var _l = __read(useState(value), 2), query = _l[0], setQuery = _l[1];
    // const debounceOnSelect = debounce(props.onSelect, 500);
    var onInputChange = function (_a) {
        var value = _a.target.value;
        setQuery(value);
        props.onSelect(null, { value: value });
    };
    useEffect(function () {
        setQuery(value);
    }, [value]);
    var onBlur = function (e) {
        setTimeout(function () { setShowModal(false); }, 200);
        props.onSelect(e, { value: query });
    };
    var handleKeyDown = function (e) {
        if (e.key === 'Enter') {
            props.onSelect(e, { value: query });
        }
    };
    return (React.createElement("div", { className: "relative flex items-center" },
        React.createElement("div", { className: stl.wrapper },
            React.createElement("input", { name: "query", onChange: onInputChange, 
                // onBlur={ onBlur }
                onFocus: function () { return setShowModal(true); }, value: query, autoFocus: true, type: type, placeholder: placeholder, onKeyDown: handleKeyDown }),
            React.createElement("div", { className: stl.right },
                showCloseButton && React.createElement("div", { onClick: onRemoveValue },
                    React.createElement(Icon, { name: "close", size: "12" })),
                showOrButton && React.createElement("div", { onClick: onAddValue, className: "color-teal" },
                    React.createElement("span", { className: "px-1" }, "or")))),
        !showOrButton && isMultilple && React.createElement("div", { className: "ml-3" }, "or")));
}
export default FilterAutoCompleteLocal;
