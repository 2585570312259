var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { connect } from 'react-redux';
import stl from './SessionSearchField.css';
import { Input } from 'UI';
import FilterModal from 'Shared/Filters/FilterModal';
import { fetchFilterSearch } from 'Duck/search';
import { debounce } from 'App/utils';
import { edit as editFilter, addFilterByKeyAndValue } from 'Duck/search';
function SessionSearchField(props) {
    var debounceFetchFilterSearch = React.useCallback(debounce(props.fetchFilterSearch, 1000), []);
    var _a = __read(useState(false), 2), showModal = _a[0], setShowModal = _a[1];
    var _b = __read(useState(''), 2), searchQuery = _b[0], setSearchQuery = _b[1];
    var onSearchChange = function (e, _a) {
        var value = _a.value;
        setSearchQuery(value);
        debounceFetchFilterSearch({ q: value });
    };
    var onAddFilter = function (filter) {
        props.addFilterByKeyAndValue(filter.key, filter.value);
    };
    return (React.createElement("div", { className: "relative" },
        React.createElement(Input
        // inputProps={ { "data-openreplay-label": "Search", "autocomplete": "off" } }
        , { 
            // inputProps={ { "data-openreplay-label": "Search", "autocomplete": "off" } }
            className: stl.searchField, onFocus: function () { return setShowModal(true); }, onBlur: function () { return setTimeout(setShowModal, 200, false); }, onChange: onSearchChange, icon: "search", iconPosition: "left", placeholder: 'Search sessions using any captured event (click, input, page, error...)', fluid: true, id: "search", type: "search", autoComplete: "off" }),
        showModal && (React.createElement("div", { className: "absolute left-0 top-20 border shadow rounded bg-white z-50" },
            React.createElement(FilterModal, { searchQuery: searchQuery, isMainSearch: true, onFilterClick: onAddFilter })))));
}
export default connect(null, { fetchFilterSearch: fetchFilterSearch, editFilter: editFilter, addFilterByKeyAndValue: addFilterByKeyAndValue })(SessionSearchField);
