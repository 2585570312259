var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Table } from '../../common';
import { List } from 'immutable';
import { filtersMap } from 'Types/filter/newFilter';
import { NoContent } from 'UI';
import { tableColumnName } from 'App/constants/filterOptions';
import { numberWithCommas } from 'App/utils';
var getColumns = function (metric) {
    return [
        {
            key: 'name',
            title: tableColumnName[metric.metricOf],
            toText: function (name) { return name || 'Unidentified'; },
            width: '70%',
        },
        {
            key: 'sessionCount',
            title: 'Sessions',
            toText: function (sessions) { return numberWithCommas(sessions); },
            width: '30%',
        },
    ];
};
function CustomMetriTable(props) {
    var _a = props.metric, metric = _a === void 0 ? {} : _a, _b = props.data, data = _b === void 0 ? { values: [] } : _b, _c = props.onClick, onClick = _c === void 0 ? function () { return null; } : _c;
    var rows = List(data.values);
    var onClickHandler = function (event, data) {
        var filters = Array();
        var filter = __assign({}, filtersMap[metric.metricOf]);
        filter.value = [data.name];
        filter.type = filter.key;
        delete filter.key;
        delete filter.operatorOptions;
        delete filter.category;
        delete filter.icon;
        delete filter.label;
        delete filter.options;
        filters.push(filter);
        onClick(filters);
    };
    return (React.createElement("div", { className: "", style: { height: '240px' } },
        React.createElement(NoContent, { show: data.values && data.values.length === 0, size: "small" },
            React.createElement(Table, { small: true, cols: getColumns(metric), rows: rows, rowClass: "group", onRowClick: onClickHandler }))));
}
export default CustomMetriTable;
