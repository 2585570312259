import React from 'react';
import { Icon, Loader } from 'UI';
import { connect } from 'react-redux';
import cn from 'classnames';
import stl from './FilterModal.css';
import { filtersMap } from 'Types/filter/newFilter';
function FilterModal(props) {
    var filters = props.filters, metaOptions = props.metaOptions, _a = props.onFilterClick, onFilterClick = _a === void 0 ? function () { return null; } : _a, filterSearchList = props.filterSearchList, _b = props.isMainSearch, isMainSearch = _b === void 0 ? false : _b, fetchingFilterSearchList = props.fetchingFilterSearchList, _c = props.searchQuery, searchQuery = _c === void 0 ? '' : _c;
    var hasSearchQuery = searchQuery && searchQuery.length > 0;
    var showSearchList = isMainSearch && searchQuery.length > 0;
    var onFilterSearchClick = function (filter) {
        var _filter = filtersMap[filter.type];
        _filter.value = [filter.value];
        onFilterClick(_filter);
    };
    return (React.createElement("div", { className: stl.wrapper, style: { width: '480px', maxHeight: '380px', overflowY: 'auto' } },
        showSearchList && (React.createElement(Loader, { size: "small", loading: fetchingFilterSearchList },
            React.createElement("div", { className: "-mx-6 px-6" }, filterSearchList && Object.keys(filterSearchList).map(function (key, index) {
                var filter = filterSearchList[key];
                var option = filtersMap[key];
                return option ? (React.createElement("div", { key: index, className: cn('mb-3') },
                    React.createElement("div", { className: "font-medium uppercase color-gray-medium text-sm mb-2" }, option.label),
                    React.createElement("div", null, filter.map(function (f, i) { return (React.createElement("div", { key: i, className: cn(stl.filterSearchItem, "cursor-pointer px-3 py-1 text-sm flex items-center"), onClick: function () { return onFilterSearchClick({ type: key, value: f.value }); } },
                        React.createElement(Icon, { className: "mr-2", name: option.icon, size: "16" }),
                        React.createElement("div", { className: "whitespace-nowrap text-ellipsis overflow-hidden" }, f.value))); })))) : React.createElement(React.Fragment, null);
            })))),
        !hasSearchQuery && (React.createElement("div", { className: "", style: { columns: "auto 200px" } }, filters && Object.keys(filters).map(function (key) { return (React.createElement("div", { className: "mb-6", key: key },
            React.createElement("div", { className: "uppercase font-medium mb-1 color-gray-medium tracking-widest text-sm" }, key),
            React.createElement("div", null, filters[key].map(function (filter) { return (React.createElement("div", { key: filter.label, className: cn(stl.optionItem, "flex items-center py-2 cursor-pointer -mx-2 px-2"), onClick: function () { return onFilterClick(filter); } },
                React.createElement(Icon, { name: filter.icon, size: "16" }),
                React.createElement("span", { className: "ml-2" }, filter.label))); })))); })))));
}
export default connect(function (state) { return ({
    filters: state.getIn(['search', 'filterList']),
    filterSearchList: state.getIn(['search', 'filterSearchList']),
    metaOptions: state.getIn(['customFields', 'list']),
    fetchingFilterSearchList: state.getIn(['search', 'fetchFilterSearch', 'loading']),
}); })(FilterModal);
