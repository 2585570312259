import React from 'react';
import cn from 'classnames';
import { TextEllipsis } from 'UI';
export default function MetaItem(props) {
    var _a = props.className, className = _a === void 0 ? '' : _a, label = props.label, value = props.value;
    return (React.createElement("div", { className: cn("flex items-center rounded", className) },
        React.createElement("span", { className: "rounded-tl rounded-bl bg-gray-light-shade px-2 color-gray-medium capitalize", style: { maxWidth: "150px" } },
            React.createElement(TextEllipsis, { text: label, className: "p-0", popupProps: { size: 'small', disabled: true } })),
        React.createElement("span", { className: "rounded-tr rounded-br bg-gray-lightest px-2 color-gray-dark capitalize", style: { maxWidth: "150px" } },
            React.createElement(TextEllipsis, { text: value, className: "p-0", popupProps: { size: 'small', disabled: true } }))));
}
