var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import FilterOperator from '../FilterOperator';
import FilterValue from '../FilterValue';
export default function SubFilterItem(props) {
    var _a = props.isFilter, isFilter = _a === void 0 ? false : _a, filterIndex = props.filterIndex, filter = props.filter;
    var canShowValues = !(filter.operator === "isAny" || filter.operator === "onAny" || filter.operator === "isUndefined");
    var onOperatorChange = function (e, _a) {
        var name = _a.name, value = _a.value;
        props.onUpdate(__assign(__assign({}, filter), { operator: value }));
    };
    return (React.createElement("div", { className: "flex items-center hover:bg-active-blue pb-4" },
        React.createElement("div", { className: "flex-shrink-0 py-1" }, filter.label),
        React.createElement(FilterOperator, { options: filter.operatorOptions, onChange: onOperatorChange, className: "mx-2 flex-shrink-0", value: filter.operator }),
        canShowValues && (React.createElement(FilterValue, { filter: filter, onUpdate: props.onUpdate }))));
}
