var AnnotationCanvas = /** @class */ (function () {
    function AnnotationCanvas() {
        var _this = this;
        this.ctx = null;
        this.painting = false;
        this.resizeCanvas = function () {
            if (!_this.canvas.parentElement) {
                return;
            }
            _this.canvas.width = _this.canvas.parentElement.offsetWidth;
            _this.canvas.height = _this.canvas.parentElement.offsetHeight;
        };
        this.lastPosition = [0, 0];
        this.start = function (p) {
            _this.painting = true;
            _this.clrTmID && clearTimeout(_this.clrTmID);
            _this.lastPosition = p;
        };
        this.stop = function () {
            if (!_this.painting) {
                return;
            }
            _this.painting = false;
            _this.fadeOut();
        };
        this.move = function (p) {
            if (!_this.ctx || !_this.painting) {
                return;
            }
            _this.ctx.globalAlpha = 1.0;
            _this.ctx.beginPath();
            _this.ctx.moveTo(_this.lastPosition[0], _this.lastPosition[1]);
            _this.ctx.lineTo(p[0], p[1]);
            _this.ctx.lineWidth = 8;
            _this.ctx.lineCap = "round";
            _this.ctx.lineJoin = "round";
            _this.ctx.strokeStyle = "red";
            _this.ctx.stroke();
            _this.lastPosition = p;
        };
        this.clrTmID = null;
        this.canvas = document.createElement('canvas');
        Object.assign(this.canvas.style, {
            position: "fixed",
            cursor: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAAXNSR0IArs4c6QAAAWNJREFUOE+l1D1Lw1AUBuD35Catg5NzaCMRMilINnGok7sguLg4OlRcBTd/hqBVB0ed7KDgIPgXhJoaG10Kgk4a83EkhcYYktimd703z31zzuESSqwGIDs1bRvAIiRcWrZ9ETFUwhJ6XTsDsPH7Le1bz08H42JkGMa09+W2CVhKBmHC7jhYlOgUTPdUEa3Q86+SIDN/j4olf43BtJMFjoJl1AgMUJMUcRInZHT+w7KgYakGoDxVafmue0hBsJeLmaapvPffziFhraDjDMKWZdvHRaNRlCi2mUNHYl55dBwrDysFZWGloTQ2EZTEJoZiTFXVmaos34Ixn9e5qNgCaHR6vW7emcFozNVmN1ERbfb9myww3bVCTK9rPsDrpCh37HnXAC3Ek5lqf9ErM0im1zUG8BmGtCqq4mEIjppoeEESA5g/JIkaLMuv7AVHEgfNohqlU/7Fol3mPodiufvS7Yz7cP4ARjbPWyYPZSMAAAAASUVORK5CYII=') 0 20, crosshair",
            left: 0,
            top: 0,
        });
    }
    AnnotationCanvas.prototype.isPainting = function () {
        return this.painting;
    };
    AnnotationCanvas.prototype.fadeOut = function () {
        var _this = this;
        var timeoutID;
        var fadeStep = function () {
            if (!_this.ctx || _this.painting) {
                return;
            }
            _this.ctx.globalCompositeOperation = 'destination-out';
            _this.ctx.fillStyle = "rgba(255, 255, 255, 0.1)";
            _this.ctx.fillRect(0, 0, _this.canvas.width, _this.canvas.height);
            _this.ctx.globalCompositeOperation = 'source-over';
            timeoutID = setTimeout(fadeStep, 100);
        };
        this.clrTmID = setTimeout(function () {
            clearTimeout(timeoutID);
            _this.ctx &&
                _this.ctx.clearRect(0, 0, _this.canvas.width, _this.canvas.height);
        }, 3700);
        fadeStep();
    };
    AnnotationCanvas.prototype.mount = function (parent) {
        parent.appendChild(this.canvas);
        this.ctx = this.canvas.getContext("2d");
        window.addEventListener("resize", this.resizeCanvas);
        this.resizeCanvas();
    };
    AnnotationCanvas.prototype.remove = function () {
        if (this.canvas.parentNode) {
            this.canvas.parentNode.removeChild(this.canvas);
        }
        window.removeEventListener("resize", this.resizeCanvas);
    };
    return AnnotationCanvas;
}());
export default AnnotationCanvas;
