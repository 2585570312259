var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { ResponsiveContainer, Tooltip } from 'recharts';
import { PieChart, Pie, Cell } from 'recharts';
import { Styles } from '../../common';
import { NoContent } from 'UI';
import { filtersMap } from 'Types/filter/newFilter';
import { numberWithCommas } from 'App/utils';
function CustomMetricPieChart(props) {
    var metric = props.metric, _a = props.data, data = _a === void 0 ? { values: [] } : _a, _b = props.onClick, onClick = _b === void 0 ? function () { return null; } : _b;
    var onClickHandler = function (event) {
        if (event && !event.payload.group) {
            var filters = Array();
            var filter = __assign({}, filtersMap[metric.metricOf]);
            filter.value = [event.payload.name];
            filter.type = filter.key;
            delete filter.key;
            delete filter.operatorOptions;
            delete filter.category;
            delete filter.icon;
            delete filter.label;
            delete filter.options;
            filters.push(filter);
            onClick(filters);
        }
    };
    return (React.createElement("div", null,
        React.createElement(NoContent, { size: "small", show: data.values && data.values.length === 0 },
            React.createElement(ResponsiveContainer, { height: 220, width: "100%" },
                React.createElement(PieChart, null,
                    React.createElement(Pie, { isAnimationActive: false, data: data.values, dataKey: "sessionCount", nameKey: "name", cx: "50%", cy: "50%", 
                        // innerRadius={40}
                        outerRadius: 70, 
                        // fill={colors[0]}
                        activeIndex: 1, onClick: onClickHandler, labelLine: function (_a) {
                            var cx = _a.cx, cy = _a.cy, midAngle = _a.midAngle, innerRadius = _a.innerRadius, outerRadius = _a.outerRadius, value = _a.value, index = _a.index;
                            var RADIAN = Math.PI / 180;
                            var radius1 = 15 + innerRadius + (outerRadius - innerRadius);
                            var radius2 = innerRadius + (outerRadius - innerRadius);
                            var x2 = cx + radius1 * Math.cos(-midAngle * RADIAN);
                            var y2 = cy + radius1 * Math.sin(-midAngle * RADIAN);
                            var x1 = cx + radius2 * Math.cos(-midAngle * RADIAN);
                            var y1 = cy + radius2 * Math.sin(-midAngle * RADIAN);
                            var percentage = value * 100 / data.values.reduce(function (a, b) { return a + b.sessionCount; }, 0);
                            if (percentage < 3) {
                                return null;
                            }
                            return (React.createElement("line", { x1: x1, y1: y1, x2: x2, y2: y2, stroke: "#3EAAAF", strokeWidth: 1 }));
                        }, label: function (_a) {
                            var cx = _a.cx, cy = _a.cy, midAngle = _a.midAngle, innerRadius = _a.innerRadius, outerRadius = _a.outerRadius, value = _a.value, index = _a.index;
                            var RADIAN = Math.PI / 180;
                            var radius = 20 + innerRadius + (outerRadius - innerRadius);
                            var x = cx + radius * Math.cos(-midAngle * RADIAN);
                            var y = cy + radius * Math.sin(-midAngle * RADIAN);
                            var percentage = (value / data.values.reduce(function (a, b) { return a + b.sessionCount; }, 0)) * 100;
                            var name = data.values[index].name || 'Unidentified';
                            name = name.length > 20 ? name.substring(0, 20) + '...' : name;
                            if (percentage < 3) {
                                return null;
                            }
                            return (React.createElement("text", { x: x, y: y, fontWeight: "400", fontSize: "12px", 
                                // fontFamily="'Source Sans Pro', 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'"
                                textAnchor: x > cx ? "start" : "end", dominantBaseline: "central", fill: '#666' },
                                name || 'Unidentified',
                                " ",
                                numberWithCommas(value)));
                        } }, data.values.map(function (entry, index) { return (React.createElement(Cell, { key: "cell-" + index, fill: Styles.colorsPie[index % Styles.colorsPie.length] })); })),
                    React.createElement(Tooltip, __assign({}, Styles.tooltip)))),
            React.createElement("div", { className: "text-sm color-gray-medium" }, "Top 5 "))));
}
export default CustomMetricPieChart;
