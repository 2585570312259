import React from 'react';
import { SlideModal } from 'UI';
import CustomMetricForm from '../CustomMetricForm';
import { connect } from 'react-redux';
import { init } from 'Duck/customMetrics';
function CustomMetricsModal(props) {
    var metric = props.metric;
    return (React.createElement(React.Fragment, null,
        React.createElement(SlideModal, { title: React.createElement("div", { className: "flex items-center" },
                React.createElement("span", { className: "mr-3" }, metric && metric.exists() ? 'Update Custom Metric' : 'Create Custom Metric')), isDisplayed: !!metric, onClose: function () { return props.init(null, true); }, content: (!!metric) && (React.createElement("div", { style: { backgroundColor: '#f6f6f6' } },
                React.createElement(CustomMetricForm, { metric: metric, onClose: function () { return props.init(null, true); } }))) })));
}
export default connect(function (state) { return ({
    metric: state.getIn(['customMetrics', 'instance']),
    alertInstance: state.getIn(['alerts', 'instance']),
    showModal: state.getIn(['customMetrics', 'showModal']),
}); }, { init: init })(CustomMetricsModal);
