import React from 'react';
import { numberWithCommas } from 'App/utils';
function CustomMetriPercentage(props) {
    var _a = props.data, data = _a === void 0 ? {} : _a;
    return (React.createElement("div", { className: "flex flex-col items-center justify-center", style: { height: '240px' } },
        React.createElement("div", { className: "text-6xl" }, numberWithCommas(data.count)),
        React.createElement("div", { className: "text-lg mt-6" }, data.previousCount + " ( " + data.countProgress + "% )"),
        React.createElement("div", { className: "color-gray-medium" }, "from previous period.")));
}
export default CustomMetriPercentage;
