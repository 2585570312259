var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { goTo as listsGoTo } from './lists';
import { update, getState } from './store';
import MessageDistributor, { INITIAL_STATE as SUPER_INITIAL_STATE } from './MessageDistributor/MessageDistributor';
var fps = 60;
var performance = window.performance || { now: Date.now.bind(Date) };
var requestAnimationFrame = window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    // @ts-ignore
    window.mozRequestAnimationFrame ||
    // @ts-ignore
    window.oRequestAnimationFrame ||
    // @ts-ignore
    window.msRequestAnimationFrame ||
    (function (callback) { return window.setTimeout(function () { callback(performance.now()); }, 1000 / fps); });
var cancelAnimationFrame = window.cancelAnimationFrame ||
    // @ts-ignore
    window.mozCancelAnimationFrame ||
    window.clearTimeout;
var HIGHEST_SPEED = 16;
var SPEED_STORAGE_KEY = "__$player-speed$__";
var SKIP_STORAGE_KEY = "__$player-skip$__";
var SKIP_TO_ISSUE_STORAGE_KEY = "__$player-skip-to-issue$__";
var AUTOPLAY_STORAGE_KEY = "__$player-autoplay$__";
var SHOW_EVENTS_STORAGE_KEY = "__$player-show-events$__";
var storedSpeed = parseInt(localStorage.getItem(SPEED_STORAGE_KEY) || "");
var initialSpeed = [1, 2, 4, 8, 16].includes(storedSpeed) ? storedSpeed : 1;
var initialSkip = !!localStorage.getItem(SKIP_STORAGE_KEY);
var initialSkipToIssue = !!localStorage.getItem(SKIP_TO_ISSUE_STORAGE_KEY);
var initialAutoplay = !!localStorage.getItem(AUTOPLAY_STORAGE_KEY);
var initialShowEvents = !!localStorage.getItem(SHOW_EVENTS_STORAGE_KEY);
export var INITIAL_STATE = __assign(__assign({}, SUPER_INITIAL_STATE), { time: 0, playing: false, completed: false, endTime: 0, inspectorMode: false, live: false, livePlay: false });
export var INITIAL_NON_RESETABLE_STATE = {
    skip: initialSkip,
    skipToIssue: initialSkipToIssue,
    autoplay: initialAutoplay,
    speed: initialSpeed,
    showEvents: initialShowEvents
};
var Player = /** @class */ (function (_super) {
    __extends(Player, _super);
    function Player() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._animationFrameRequestId = 0;
        return _this;
    }
    Player.prototype._setTime = function (time, index) {
        update({
            time: time,
            completed: false,
        });
        _super.prototype.move.call(this, time, index);
        listsGoTo(time, index);
    };
    Player.prototype._startAnimation = function () {
        var _this = this;
        var prevTime = getState().time;
        var animationPrevTime = performance.now();
        var nextFrame = function (animationCurrentTime) {
            var _a = getState(), speed = _a.speed, skip = _a.skip, autoplay = _a.autoplay, skipIntervals = _a.skipIntervals, endTime = _a.endTime, live = _a.live, livePlay = _a.livePlay, disconnected = _a.disconnected, messagesLoading = _a.messagesLoading, cssLoading = _a.cssLoading;
            var diffTime = messagesLoading || cssLoading || disconnected
                ? 0
                : Math.max(animationCurrentTime - animationPrevTime, 0) * (live ? 1 : speed);
            var time = prevTime + diffTime;
            var skipInterval = skip && skipIntervals.find(function (si) { return si.contains(time); }); // TODO: good skip by messages
            if (skipInterval)
                time = skipInterval.end;
            var fmt = _super.prototype.getFirstMessageTime.call(_this);
            if (time < fmt)
                time = fmt; // ?
            var lmt = _super.prototype.getLastMessageTime.call(_this);
            if (livePlay && time < lmt)
                time = lmt;
            if (endTime < lmt) {
                update({
                    endTime: lmt,
                });
            }
            prevTime = time;
            animationPrevTime = animationCurrentTime;
            var completed = !live && time >= endTime;
            if (completed) {
                _this._setTime(endTime);
                return update({
                    playing: false,
                    completed: true,
                });
            }
            if (live && time > endTime) {
                update({
                    endTime: time,
                });
            }
            _this._setTime(time);
            _this._animationFrameRequestId = requestAnimationFrame(nextFrame);
        };
        this._animationFrameRequestId = requestAnimationFrame(nextFrame);
    };
    Player.prototype.play = function () {
        cancelAnimationFrame(this._animationFrameRequestId);
        update({ playing: true });
        this._startAnimation();
    };
    Player.prototype.pause = function () {
        cancelAnimationFrame(this._animationFrameRequestId);
        update({ playing: false });
    };
    Player.prototype.togglePlay = function () {
        var _a = getState(), playing = _a.playing, completed = _a.completed;
        if (playing) {
            this.pause();
        }
        else if (completed) {
            this._setTime(0);
            this.play();
        }
        else {
            this.play();
        }
    };
    Player.prototype.jump = function (time, index) {
        if (time === void 0) { time = getState().time; }
        var live = getState().live;
        if (live)
            return;
        if (getState().playing) {
            cancelAnimationFrame(this._animationFrameRequestId);
            // this._animationFrameRequestId = requestAnimationFrame(() => {
            this._setTime(time, index);
            this._startAnimation();
            update({ livePlay: time === getState().endTime });
            //});
        }
        else {
            //this._animationFrameRequestId = requestAnimationFrame(() => {
            this._setTime(time, index);
            update({ livePlay: time === getState().endTime });
            //});
        }
    };
    Player.prototype.toggleSkip = function () {
        var skip = !getState().skip;
        localStorage.setItem(SKIP_STORAGE_KEY, "" + skip);
        update({ skip: skip });
    };
    Player.prototype.toggleInspectorMode = function (flag, clickCallback) {
        if (typeof flag !== 'boolean') {
            var inspectorMode = getState().inspectorMode;
            flag = !inspectorMode;
        }
        if (flag) {
            this.pause();
            update({ inspectorMode: true });
            return _super.prototype.enableInspector.call(this, clickCallback);
        }
        else {
            _super.prototype.disableInspector.call(this);
            update({ inspectorMode: false });
        }
    };
    Player.prototype.markTargets = function (targets) {
        this.pause();
        this.setMarkedTargets(targets);
    };
    Player.prototype.activeTarget = function (index) {
        this.setActiveTarget(index);
    };
    Player.prototype.toggleSkipToIssue = function () {
        var skipToIssue = !getState().skipToIssue;
        localStorage.setItem(SKIP_TO_ISSUE_STORAGE_KEY, "" + skipToIssue);
        update({ skipToIssue: skipToIssue });
    };
    Player.prototype.toggleAutoplay = function () {
        var autoplay = !getState().autoplay;
        localStorage.setItem(AUTOPLAY_STORAGE_KEY, "" + autoplay);
        update({ autoplay: autoplay });
    };
    Player.prototype.toggleEvents = function () {
        var showEvents = !getState().showEvents;
        localStorage.setItem(SHOW_EVENTS_STORAGE_KEY, "" + showEvents);
        update({ showEvents: showEvents });
    };
    Player.prototype._updateSpeed = function (speed) {
        localStorage.setItem(SPEED_STORAGE_KEY, "" + speed);
        update({ speed: speed });
    };
    Player.prototype.toggleSpeed = function () {
        var speed = getState().speed;
        this._updateSpeed(speed < HIGHEST_SPEED ? speed * 2 : 1);
    };
    Player.prototype.speedUp = function () {
        var speed = getState().speed;
        this._updateSpeed(Math.min(HIGHEST_SPEED, speed * 2));
    };
    Player.prototype.speedDown = function () {
        var speed = getState().speed;
        this._updateSpeed(Math.max(1, speed / 2));
    };
    Player.prototype.clean = function () {
        this.pause();
        _super.prototype.clean.call(this);
    };
    return Player;
}(MessageDistributor));
export default Player;
