import React from 'react';
import cn from 'classnames';
import MetaItem from '../MetaItem';
import MetaMoreButton from '../MetaMoreButton';
export default function SessionMetaList(props) {
    var _a = props.className, className = _a === void 0 ? '' : _a, metaList = props.metaList, _b = props.maxLength, maxLength = _b === void 0 ? 4 : _b;
    return (React.createElement("div", { className: cn("text-sm flex items-start", className) },
        metaList.slice(0, maxLength).map(function (_a, index) {
            var label = _a.label, value = _a.value;
            return (React.createElement(MetaItem, { key: index, label: label, value: '' + value, className: "mr-3" }));
        }),
        metaList.length > maxLength && (React.createElement(MetaMoreButton, { list: metaList, maxLength: maxLength }))));
}
