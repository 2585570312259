var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { editSavedSearch as edit, save, remove } from 'Duck/search';
import { Button, Modal, Form, Icon, Checkbox } from 'UI';
import { confirm } from 'UI/Confirmation';
import stl from './SaveSearchModal.css';
import cn from 'classnames';
function SaveSearchModal(props) {
    var _this = this;
    var savedSearch = props.savedSearch, filter = props.filter, loading = props.loading, show = props.show, closeHandler = props.closeHandler;
    var _a = __read(useState(savedSearch ? savedSearch.name : ''), 2), name = _a[0], setName = _a[1];
    var onNameChange = function (_a) {
        var value = _a.target.value;
        props.edit({ name: value });
        // setName(value);
    };
    var onSave = function () {
        var filter = props.filter, closeHandler = props.closeHandler;
        // if (name.trim() === '') return;
        props.save(savedSearch.exists() ? savedSearch.searchId : null).then(function () {
            // this.props.fetchFunnelsList();
            closeHandler();
        });
    };
    var onDelete = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, confirm({
                        header: 'Confirm',
                        confirmButton: 'Yes, Delete',
                        confirmation: "Are you sure you want to permanently delete this Saved search?",
                    })];
                case 1:
                    if (_a.sent()) {
                        props.remove(savedSearch.searchId).then(function () {
                            closeHandler();
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var onChangeOption = function (e, _a) {
        var _b;
        var checked = _a.checked, name = _a.name;
        return props.edit((_b = {}, _b[name] = checked, _b));
    };
    return (React.createElement(Modal, { size: "tiny", open: show },
        React.createElement(Modal.Header, { className: stl.modalHeader },
            React.createElement("div", null, 'Save Search'),
            React.createElement(Icon, { role: "button", tabIndex: "-1", color: "gray-dark", size: "18", name: "close", onClick: closeHandler })),
        React.createElement(Modal.Content, null,
            React.createElement(Form, { onSubmit: onSave },
                React.createElement(Form.Field, null,
                    React.createElement("label", null, 'Title:'),
                    React.createElement("input", { autoFocus: true, 
                        // className={ stl.name }
                        name: "name", value: savedSearch.name, onChange: onNameChange, placeholder: "Title" })),
                React.createElement(Form.Field, null,
                    React.createElement("div", { className: cn("flex items-center", { 'disabled': savedSearch.exists() && savedSearch.userId !== props.userId }) },
                        React.createElement(Checkbox, { name: "isPublic", className: "font-medium mr-3", type: "checkbox", checked: savedSearch.isPublic, onClick: onChangeOption }),
                        React.createElement("div", { className: "flex items-center cursor-pointer", onClick: function () { return props.edit({ 'isPublic': !savedSearch.isPublic }); } },
                            React.createElement(Icon, { name: "user-friends", size: "16" }),
                            React.createElement("span", { className: "ml-2" }, " Team Visible"))))),
            savedSearch.exists() && React.createElement("div", { className: "mt-4" }, "Changes in filters will be updated.")),
        React.createElement(Modal.Actions, { className: "flex items-center px-6" },
            React.createElement("div", { className: "mr-auto" },
                React.createElement(Button, { primary: true, onClick: onSave, loading: loading, disabled: !savedSearch.validate() }, savedSearch.exists() ? 'Update' : 'Create'),
                React.createElement(Button, { className: stl.cancelButton, marginRight: true, onClick: closeHandler }, 'Cancel')),
            savedSearch && React.createElement(Button, { noPadding: true, className: stl.cancelButton, marginRight: true, onClick: onDelete },
                React.createElement(Icon, { name: "trash", size: "18" })))));
}
export default connect(function (state) { return ({
    userId: state.getIn(['user', 'account', 'id']),
    savedSearch: state.getIn(['search', 'savedSearch']),
    filter: state.getIn(['search', 'instance']),
    loading: state.getIn(['search', 'saveRequest', 'loading']) ||
        state.getIn(['search', 'updateRequest', 'loading']),
}); }, { edit: edit, save: save, remove: remove })(SaveSearchModal);
