var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import FilterModal from '../FilterModal';
import LiveFilterModal from '../LiveFilterModal';
import OutsideClickDetectingDiv from 'Shared/OutsideClickDetectingDiv';
import { Icon } from 'UI';
import { connect } from 'react-redux';
import { assist as assistRoute, isRoute } from "App/routes";
var ASSIST_ROUTE = assistRoute();
function FilterSelection(props) {
    var filter = props.filter, onFilterClick = props.onFilterClick, children = props.children, _a = props.isLive, isLive = _a === void 0 ? true : _a;
    var _b = __read(useState(false), 2), showModal = _b[0], setShowModal = _b[1];
    return (React.createElement("div", { className: "relative flex-shrink-0" },
        React.createElement(OutsideClickDetectingDiv, { className: "relative", onClickOutside: function () { return setTimeout(function () {
                setShowModal(false);
            }, 200); } }, children ? React.cloneElement(children, { onClick: function (e) {
                e.stopPropagation();
                e.preventDefault();
                setShowModal(true);
            } }) : (React.createElement("div", { className: "rounded py-1 px-3 flex items-center cursor-pointer bg-gray-lightest text-ellipsis hover:bg-gray-light-shade", style: { width: '140px', height: '26px', border: 'solid thin #e9e9e9' }, onClick: function () { return setShowModal(true); } },
            React.createElement("span", { className: "mr-auto truncate" }, filter.label),
            React.createElement(Icon, { name: "chevron-down", size: "14" })))),
        showModal && (React.createElement("div", { className: "absolute left-0 top-20 border shadow rounded bg-white z-50" }, isRoute(ASSIST_ROUTE, window.location.pathname) ? React.createElement(LiveFilterModal, { onFilterClick: onFilterClick }) : React.createElement(FilterModal, { onFilterClick: onFilterClick })))));
}
export default connect(function (state) { return ({
    isLive: state.getIn(['sessions', 'activeTab']).type === 'live',
}); }, {})(FilterSelection);
