var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { Duration } from 'luxon';
import { durationFormatted, convertTimestampToUtcTimestamp } from 'App/date';
function Counter(_a) {
    var startTime = _a.startTime, className = _a.className;
    var intervalId;
    var _b = __read(useState(new Date().getTime() - convertTimestampToUtcTimestamp(startTime)), 2), duration = _b[0], setDuration = _b[1];
    var formattedDuration = durationFormatted(Duration.fromMillis(duration));
    useEffect(function () {
        if (!intervalId) {
            intervalId = setInterval(function () {
                setDuration(duration + 1000);
            }, 1000);
        }
        return function () { return clearInterval(intervalId); };
    }, [duration]);
    return (React.createElement("div", { className: className }, startTime && formattedDuration));
}
export default Counter;
