import styles from './screen.css';
import { getState } from '../../../store';
export var INITIAL_STATE = {
    width: 0,
    height: 0,
};
var BaseScreen = /** @class */ (function () {
    function BaseScreen() {
        var _this = this;
        this.parentElement = null;
        this.boundingRect = null;
        this.s = 1;
        this.scale = function () {
            _this._scale();
        };
        var iframe = document.createElement('iframe');
        iframe.className = styles.iframe;
        this.iframe = iframe;
        var overlay = document.createElement('div');
        overlay.className = styles.overlay;
        this.overlay = overlay;
        var screen = document.createElement('div');
        screen.className = styles.screen;
        screen.appendChild(iframe);
        screen.appendChild(overlay);
        this.screen = screen;
    }
    BaseScreen.prototype.attach = function (parentElement) {
        var _this = this;
        if (this.parentElement) {
            throw new Error("BaseScreen: Trying to attach an attached screen.");
        }
        parentElement.appendChild(this.screen);
        this.parentElement = parentElement;
        // parentElement.onresize = this.scale;
        window.addEventListener('resize', this.scale);
        this.scale();
        /* == For the Inspecting Document content  == */
        this.overlay.addEventListener('contextmenu', function () {
            _this.overlay.style.display = 'none';
            var doc = _this.document;
            if (!doc) {
                return;
            }
            var returnOverlay = function () {
                _this.overlay.style.display = 'block';
                doc.removeEventListener('mousemove', returnOverlay);
                doc.removeEventListener('mouseclick', returnOverlay); // TODO: prevent default in case of input selection
            };
            doc.addEventListener('mousemove', returnOverlay);
            doc.addEventListener('mouseclick', returnOverlay);
        });
    };
    Object.defineProperty(BaseScreen.prototype, "window", {
        get: function () {
            return this.iframe.contentWindow;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseScreen.prototype, "document", {
        get: function () {
            return this.iframe.contentDocument;
        },
        enumerable: false,
        configurable: true
    });
    BaseScreen.prototype.getBoundingClientRect = function () {
        if (this.boundingRect === null) {
            return this.boundingRect = this.overlay.getBoundingClientRect(); // expensive operation?
        }
        return this.boundingRect;
    };
    BaseScreen.prototype.getInternalViewportCoordinates = function (_a) {
        var x = _a.x, y = _a.y;
        var _b = this.getBoundingClientRect(), overlayX = _b.x, overlayY = _b.y, width = _b.width;
        var screenWidth = this.overlay.offsetWidth;
        var scale = screenWidth / width;
        var screenX = (x - overlayX) * scale;
        var screenY = (y - overlayY) * scale;
        return { x: Math.round(screenX), y: Math.round(screenY) };
    };
    BaseScreen.prototype.getCurrentScroll = function () {
        var _a;
        var docEl = (_a = this.document) === null || _a === void 0 ? void 0 : _a.documentElement;
        var x = docEl ? docEl.scrollLeft : 0;
        var y = docEl ? docEl.scrollTop : 0;
        return { x: x, y: y };
    };
    BaseScreen.prototype.getInternalCoordinates = function (p) {
        var _a = this.getInternalViewportCoordinates(p), x = _a.x, y = _a.y;
        var sc = this.getCurrentScroll();
        return { x: x + sc.x, y: y + sc.y };
    };
    BaseScreen.prototype.getElementFromInternalPoint = function (_a) {
        var _b;
        var x = _a.x, y = _a.y;
        // elementFromPoint && elementFromPoints require viewpoint-related coordinates, 
        //                                                 not document-related
        return ((_b = this.document) === null || _b === void 0 ? void 0 : _b.elementFromPoint(x, y)) || null;
    };
    BaseScreen.prototype.getElementsFromInternalPoint = function (_a) {
        var _b, _c, _d, _e, _f;
        var x = _a.x, y = _a.y;
        // @ts-ignore (IE, Edge)
        if (typeof ((_b = this.document) === null || _b === void 0 ? void 0 : _b.msElementsFromRect) === 'function') {
            // @ts-ignore
            return Array.prototype.slice.call((_c = this.document) === null || _c === void 0 ? void 0 : _c.msElementsFromRect(x, y)) || [];
        }
        if (typeof ((_d = this.document) === null || _d === void 0 ? void 0 : _d.elementsFromPoint) === 'function') {
            return ((_e = this.document) === null || _e === void 0 ? void 0 : _e.elementsFromPoint(x, y)) || [];
        }
        var el = (_f = this.document) === null || _f === void 0 ? void 0 : _f.elementFromPoint(x, y);
        return el ? [el] : [];
    };
    BaseScreen.prototype.getElementFromPoint = function (point) {
        return this.getElementFromInternalPoint(this.getInternalViewportCoordinates(point));
    };
    BaseScreen.prototype.getElementsFromPoint = function (point) {
        return this.getElementsFromInternalPoint(this.getInternalViewportCoordinates(point));
    };
    BaseScreen.prototype.getElementBySelector = function (selector) {
        var _a;
        if (!selector)
            return null;
        try {
            return ((_a = this.document) === null || _a === void 0 ? void 0 : _a.querySelector(selector)) || null;
        }
        catch (e) {
            console.error("Can not select element. ", e);
            return null;
        }
    };
    BaseScreen.prototype.display = function (flag) {
        if (flag === void 0) { flag = true; }
        this.screen.style.display = flag ? '' : 'none';
    };
    BaseScreen.prototype.displayFrame = function (flag) {
        if (flag === void 0) { flag = true; }
        this.iframe.style.display = flag ? '' : 'none';
    };
    BaseScreen.prototype.getScale = function () {
        return this.s;
    };
    BaseScreen.prototype._scale = function () {
        if (!this.parentElement)
            return;
        var _a = getState(), height = _a.height, width = _a.width;
        var _b = this.parentElement, offsetWidth = _b.offsetWidth, offsetHeight = _b.offsetHeight;
        this.s = Math.min(offsetWidth / width, offsetHeight / height);
        if (this.s > 1) {
            this.s = 1;
        }
        else {
            this.s = Math.round(this.s * 1e3) / 1e3;
        }
        this.screen.style.transform = "scale(" + this.s + ") translate(-50%, -50%)";
        this.screen.style.width = width + 'px';
        this.screen.style.height = height + 'px';
        this.iframe.style.width = width + 'px';
        this.iframe.style.height = height + 'px';
        this.boundingRect = this.overlay.getBoundingClientRect();
    };
    BaseScreen.prototype.clean = function () {
        window.removeEventListener('resize', this.scale);
    };
    return BaseScreen;
}());
export default BaseScreen;
