import React from 'react';
import cn from 'classnames';
import stl from './ErrorBars.css';
var GOOD = 'Good';
var LESS_CRITICAL = 'Few Issues';
var CRITICAL = 'Many Issues';
var getErrorState = function (count) {
    if (count === 0) {
        return GOOD;
    }
    if (count < 3) {
        return LESS_CRITICAL;
    }
    return CRITICAL;
};
export default function ErrorBars(props) {
    var _a = props.count, count = _a === void 0 ? 2 : _a;
    var state = React.useMemo(function () { return getErrorState(count); }, [count]);
    var isGood = state === GOOD;
    var showFirstBar = (state === LESS_CRITICAL || state === CRITICAL);
    var showSecondBar = (state === CRITICAL);
    // const showThirdBar = (state === GOOD || state === CRITICAL);
    // const bgColor = { 'bg-red' : state === CRITICAL, 'bg-red2' : state === LESS_CRITICAL }
    var bgColor = 'bg-red2';
    return isGood ? React.createElement(React.Fragment, null) : (React.createElement("div", null,
        React.createElement("div", { className: "relative", style: { width: '100px' } },
            React.createElement("div", { className: "grid grid-cols-3 gap-1 absolute inset-0", style: { opacity: '1' } },
                showFirstBar && React.createElement("div", { className: cn("rounded-tl rounded-bl", bgColor, stl.bar) }),
                showSecondBar && React.createElement("div", { className: cn("rounded-tl rounded-bl", bgColor, stl.bar) })),
            React.createElement("div", { className: "grid grid-cols-3 gap-1", style: { opacity: '0.3' } },
                React.createElement("div", { className: cn("rounded-tl rounded-bl", bgColor, stl.bar) }),
                React.createElement("div", { className: cn(bgColor, stl.bar) }))),
        React.createElement("div", { className: "mt-1 color-gray-medium text-sm" }, state)));
}
