var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { SlideModal } from 'UI';
import { init, edit, save, remove } from 'Duck/alerts';
import { fetchList as fetchWebhooks } from 'Duck/webhook';
import AlertForm from '../AlertForm';
import { connect } from 'react-redux';
import { setShowAlerts } from 'Duck/dashboard';
import { SLACK, WEBHOOK } from 'App/constants/schedule';
import { confirm } from 'UI/Confirmation';
function AlertFormModal(props) {
    var _this = this;
    var _a = props.metricId, metricId = _a === void 0 ? null : _a, _b = props.showModal, showModal = _b === void 0 ? false : _b, webhooks = props.webhooks;
    var _c = __read(useState(false), 2), showForm = _c[0], setShowForm = _c[1];
    useEffect(function () {
        props.fetchWebhooks();
    }, []);
    var slackChannels = webhooks.filter(function (hook) { return hook.type === SLACK; }).map(function (_a) {
        var webhookId = _a.webhookId, name = _a.name;
        return ({ value: webhookId, text: name });
    }).toJS();
    var hooks = webhooks.filter(function (hook) { return hook.type === WEBHOOK; }).map(function (_a) {
        var webhookId = _a.webhookId, name = _a.name;
        return ({ value: webhookId, text: name });
    }).toJS();
    var saveAlert = function (instance) {
        var wasUpdating = instance.exists();
        props.save(instance).then(function () {
            if (!wasUpdating) {
                toggleForm(null, false);
            }
            if (props.onClose) {
                props.onClose();
            }
        });
    };
    var onDelete = function (instance) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, confirm({
                        header: 'Confirm',
                        confirmButton: 'Yes, Delete',
                        confirmation: "Are you sure you want to permanently delete this alert?"
                    })];
                case 1:
                    if (_a.sent()) {
                        props.remove(instance.alertId).then(function () {
                            toggleForm(null, false);
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var toggleForm = function (instance, state) {
        if (instance) {
            props.init(instance);
        }
        return setShowForm(state ? state : !showForm);
    };
    return (React.createElement(SlideModal, { title: React.createElement("div", { className: "flex items-center" },
            React.createElement("span", { className: "mr-3" }, 'Create Alert')), isDisplayed: showModal, onClose: props.onClose, size: "medium", content: showModal &&
            React.createElement(AlertForm, { metricId: metricId, edit: props.edit, slackChannels: slackChannels, webhooks: hooks, onSubmit: saveAlert, onClose: props.onClose, onDelete: onDelete, style: { width: '580px', height: '100vh - 200px' } }) }));
}
export default connect(function (state) { return ({
    webhooks: state.getIn(['webhooks', 'list']),
    instance: state.getIn(['alerts', 'instance']),
}); }, { init: init, edit: edit, save: save, remove: remove, fetchWebhooks: fetchWebhooks, setShowAlerts: setShowAlerts })(AlertFormModal);
