import React from 'react';
import { Icon } from 'UI';
import cn from 'classnames';
export default function SessionInfoItem(props) {
    var label = props.label, icon = props.icon, value = props.value, comp = props.comp, _a = props.isLast, isLast = _a === void 0 ? false : _a;
    return (React.createElement("div", { className: cn("flex items-center w-full py-2", { 'border-b': !isLast }) },
        React.createElement("div", { className: "px-2 capitalize", style: { width: '30px' } },
            icon && React.createElement(Icon, { name: icon, size: "16" }),
            comp && comp),
        React.createElement("div", { className: "px-2 capitalize", style: { minWidth: '160px' } }, label),
        React.createElement("div", { className: "color-gray-medium px-2", style: { minWidth: '130px' } }, value)));
}
