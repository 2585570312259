var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useRef, useEffect } from 'react';
import { Icon } from 'UI';
function SeriesName(props) {
    var _a = props.seriesIndex, seriesIndex = _a === void 0 ? 1 : _a;
    var _b = __read(useState(false), 2), editing = _b[0], setEditing = _b[1];
    var _c = __read(useState(props.name), 2), name = _c[0], setName = _c[1];
    var ref = useRef(null);
    var write = function (_a) {
        var _b = _a.target, value = _b.value, name = _b.name;
        setName(value);
    };
    var onBlur = function () {
        setEditing(false);
        props.onUpdate(name);
    };
    useEffect(function () {
        if (editing) {
            ref.current.focus();
        }
    }, [editing]);
    useEffect(function () {
        setName(props.name);
    }, [props.name]);
    // const { name } = props;
    return (React.createElement("div", { className: "flex items-center" },
        editing ? (React.createElement("input", { ref: ref, name: "name", className: "fluid border-0 -mx-2 px-2 h-8", value: name, 
            // readOnly={!editing} 
            onChange: write, onBlur: onBlur, onFocus: function () { return setEditing(true); } })) : (React.createElement("div", { className: "text-base h-8 flex items-center border-transparent" }, name.trim() === '' ? 'Seriess ' + (seriesIndex + 1) : name)),
        React.createElement("div", { className: "ml-3 cursor-pointer", onClick: function () { return setEditing(true); } },
            React.createElement(Icon, { name: "pencil", size: "14" }))));
}
export default SeriesName;
