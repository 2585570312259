var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { useEffect, useState } from 'react';
import { SlideModal, NoContent, Dropdown, Icon, TimezoneDropdown, Loader } from 'UI';
import SessionItem from 'Shared/SessionItem';
import stl from './SessionListModal.css';
import { connect } from 'react-redux';
import { fetchSessionList, setActiveWidget } from 'Duck/customMetrics';
import { DateTime } from 'luxon';
function SessionListModal(props) {
    var activeWidget = props.activeWidget, loading = props.loading, list = props.list;
    var _a = __read(useState([
        { text: 'All', value: 'all' },
    ]), 2), seriesOptions = _a[0], setSeriesOptions = _a[1];
    var _b = __read(useState('all'), 2), activeSeries = _b[0], setActiveSeries = _b[1];
    useEffect(function () {
        if (!activeWidget || !activeWidget.widget)
            return;
        props.fetchSessionList({
            metricId: activeWidget.widget.metricId,
            startDate: activeWidget.startTimestamp,
            endDate: activeWidget.endTimestamp,
            filters: activeWidget.filters || [],
        });
    }, [activeWidget]);
    useEffect(function () {
        if (!list)
            return;
        var seriesOptions = list.map(function (item) { return ({
            text: item.seriesName,
            value: item.seriesId,
        }); });
        setSeriesOptions(__spread([
            { text: 'All', value: 'all' }
        ], seriesOptions));
    }, [list]);
    var getListSessionsBySeries = function (seriesId) {
        var arr = [];
        list.forEach(function (element) {
            if (seriesId === 'all') {
                var sessionIds_1 = arr.map(function (i) { return i.sessionId; });
                arr.push.apply(arr, __spread(element.sessions.filter(function (i) { return !sessionIds_1.includes(i.sessionId); })));
            }
            else {
                if (element.seriesId === seriesId) {
                    arr.push.apply(arr, __spread(element.sessions));
                }
            }
        });
        return arr;
    };
    var writeOption = function (e, _a) {
        var name = _a.name, value = _a.value;
        return setActiveSeries(value);
    };
    var filteredSessions = getListSessionsBySeries(activeSeries);
    var startTime = DateTime.fromMillis(activeWidget.startTimestamp).toFormat('LLL dd, yyyy HH:mm a');
    var endTime = DateTime.fromMillis(activeWidget.endTimestamp).toFormat('LLL dd, yyyy HH:mm a');
    return (React.createElement(SlideModal, { title: activeWidget && (React.createElement("div", { className: "flex items-center" },
            React.createElement("div", { className: "mr-auto" },
                activeWidget.widget.name,
                " "))), isDisplayed: !!activeWidget, onClose: function () { return props.setActiveWidget(null); }, content: activeWidget && (React.createElement("div", { className: stl.wrapper },
            React.createElement("div", { className: "mb-6 flex items-center" },
                React.createElement("div", { className: "mr-auto" },
                    "Showing all sessions between ",
                    React.createElement("span", { className: "font-medium" }, startTime),
                    " and ",
                    React.createElement("span", { className: "font-medium" }, endTime),
                    " "),
                React.createElement("div", { className: "flex items-center ml-6" },
                    React.createElement("div", { className: "flex items-center" },
                        React.createElement("span", { className: "mr-2 color-gray-medium" }, "Timezone"),
                        React.createElement(TimezoneDropdown, null)),
                    activeWidget.widget.metricType !== 'table' && (React.createElement("div", { className: "flex items-center ml-6" },
                        React.createElement("span", { className: "mr-2 color-gray-medium" }, "Series"),
                        React.createElement(Dropdown, { className: stl.dropdown, direction: "left", options: seriesOptions, name: "change", value: activeSeries, onChange: writeOption, id: "change-dropdown", 
                            // icon={null}
                            icon: React.createElement(Icon, { name: "chevron-down", color: "gray-dark", size: "14", className: stl.dropdownIcon }) }))))),
            React.createElement(Loader, { loading: loading },
                React.createElement(NoContent, { show: !loading && (filteredSessions.length === 0), title: "No recordings found!", icon: "exclamation-circle" }, filteredSessions.map(function (session) { return React.createElement(SessionItem, { key: session.sessionId, session: session }); }))))) }));
}
export default connect(function (state) { return ({
    loading: state.getIn(['customMetrics', 'fetchSessionList', 'loading']),
    list: state.getIn(['customMetrics', 'sessionList']),
}); }, { fetchSessionList: fetchSessionList, setActiveWidget: setActiveWidget })(SessionListModal);
