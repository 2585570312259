import React from 'react';
import ovStl from './overlay.css';
import { ConnectionStatus } from 'Player/MessageDistributor/managers/AssistManager';
import { Loader } from 'UI';
export default function LiveStatusText(_a) {
    var text = _a.text, concetionStatus = _a.concetionStatus;
    var renderView = function () {
        switch (concetionStatus) {
            case ConnectionStatus.Closed:
                return (React.createElement("div", { className: "flex flex-col items-center text-center" },
                    React.createElement("div", { className: "text-lg -mt-8" }, "Session not found"),
                    React.createElement("div", { className: "text-sm" },
                        "The remote session doesn\u2019t exist anymore. ",
                        React.createElement("br", null),
                        " The user may have closed the tab/browser while you were trying to establish a connection.")));
            case ConnectionStatus.Connecting:
                return (React.createElement("div", { className: "flex flex-col items-center" },
                    React.createElement(Loader, { loading: true, size: "small" }),
                    React.createElement("div", { className: "text-lg -mt-8" }, "Connecting..."),
                    React.createElement("div", { className: "text-sm" }, "Establishing a connection with the remote session.")));
            case ConnectionStatus.WaitingMessages:
                return (React.createElement("div", { className: "flex flex-col items-center" },
                    React.createElement(Loader, { loading: true, size: "small" }),
                    React.createElement("div", { className: "text-lg -mt-8" }, "Waiting for the session to become active..."),
                    React.createElement("div", { className: "text-sm" }, "If it's taking too much time, it could mean the user is simply inactive.")));
            case ConnectionStatus.Connected:
                return (React.createElement("div", { className: "flex flex-col items-center" },
                    React.createElement("div", { className: "text-lg -mt-8" }, "Connected")));
            case ConnectionStatus.Inactive:
                return (React.createElement("div", { className: "flex flex-col items-center" },
                    React.createElement(Loader, { loading: true, size: "small" }),
                    React.createElement("div", { className: "text-lg -mt-8" }, "Waiting for the session to become active..."),
                    React.createElement("div", { className: "text-sm" }, "If it's taking too much time, it could mean the user is simply inactive.")));
            case ConnectionStatus.Disconnected:
                return (React.createElement("div", { className: "flex flex-col items-center" },
                    React.createElement("div", { className: "text-lg -mt-8" }, "Disconnected"),
                    React.createElement("div", { className: "text-sm" }, "The connection was lost with the remote session. The user may have simply closed the tab/browser.")));
            case ConnectionStatus.Error:
                return (React.createElement("div", { className: "flex flex-col items-center" },
                    React.createElement("div", { className: "text-lg -mt-8" }, "Error"),
                    React.createElement("div", { className: "text-sm" }, "Something wrong just happened. Try refreshing the page.")));
        }
    };
    return React.createElement("div", { className: ovStl.overlay }, renderView());
}
