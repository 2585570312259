import React from 'react';
import ReloadButton from '../ReloadButton';
import { connect } from 'react-redux';
import { fetchLiveList } from 'Duck/sessions';
function LiveSessionReloadButton(props) {
    var loading = props.loading;
    return (React.createElement(ReloadButton, { loading: loading, onClick: props.fetchLiveList, className: "cursor-pointer" }));
}
export default connect(function (state) { return ({
    loading: state.getIn(['sessions', 'fetchLiveListRequest', 'loading']),
}); }, { fetchLiveList: fetchLiveList })(LiveSessionReloadButton);
