import React from 'react';
import LiveSessionList from 'Shared/LiveSessionList';
import LiveSessionSearch from 'Shared/LiveSessionSearch';
import cn from 'classnames';
import withPageTitle from 'HOCs/withPageTitle';
import withPermissions from 'HOCs/withPermissions';
function Assist() {
    return (React.createElement("div", { className: "page-margin container-90 flex relative" },
        React.createElement("div", { className: "flex-1 flex" },
            React.createElement("div", { className: cn("w-full mx-auto"), style: { maxWidth: '1300px' } },
                React.createElement(LiveSessionSearch, null),
                React.createElement("div", { className: "my-4" }),
                React.createElement(LiveSessionList, null)))));
}
export default withPageTitle("Assist - OpenReplay")(withPermissions(['ASSIST_LIVE'])(Assist));
