import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import stl from './roleForm.css';
import { save, edit } from 'Duck/roles';
import { Input, Button, Checkbox, Dropdown, Icon } from 'UI';
var RoleForm = function (props) {
    var role = props.role, edit = props.edit, save = props.save, closeModal = props.closeModal, saving = props.saving, permissions = props.permissions, projectOptions = props.projectOptions, permissionsMap = props.permissionsMap, projectsMap = props.projectsMap;
    var focusElement = useRef(null);
    var _save = function () {
        save(role).then(function () {
            closeModal(role.exists() ? "Role updated" : "Role created");
        });
    };
    var write = function (_a) {
        var _b;
        var _c = _a.target, value = _c.value, name = _c.name;
        return edit((_b = {}, _b[name] = value, _b));
    };
    var onChangePermissions = function (e) {
        var permissions = role.permissions;
        var index = permissions.indexOf(e);
        var _perms = permissions.contains(e) ? permissions.remove(index) : permissions.push(e);
        edit({ permissions: _perms });
    };
    var onChangeProjects = function (e) {
        var projects = role.projects;
        var index = projects.indexOf(e);
        var _projects = index === -1 ? projects.push(e) : projects.remove(index);
        edit({ projects: _projects });
    };
    var writeOption = function (e, _a) {
        var name = _a.name, value = _a.value;
        if (name === 'permissions') {
            onChangePermissions(value);
        }
        else if (name === 'projects') {
            onChangeProjects(value);
        }
    };
    var toggleAllProjects = function () {
        var allProjects = role.allProjects;
        edit({ allProjects: !allProjects });
    };
    useEffect(function () {
        focusElement && focusElement.current && focusElement.current.focus();
    }, []);
    return (React.createElement("div", { className: stl.form },
        React.createElement("form", { onSubmit: _save },
            React.createElement("div", { className: "form-group" },
                React.createElement("label", null, 'Title'),
                React.createElement(Input, { ref: focusElement, name: "name", value: role.name, onChange: write, className: stl.input, id: "name-field", placeholder: "Ex. Admin" })),
            React.createElement("div", { className: "form-group flex flex-col" },
                React.createElement("label", null, 'Project Access'),
                React.createElement("div", { className: "flex my-3" },
                    React.createElement(Checkbox, { name: "allProjects", className: "font-medium", type: "checkbox", checked: role.allProjects, onClick: toggleAllProjects, label: '' }),
                    React.createElement("div", { className: "cursor-pointer", onClick: toggleAllProjects },
                        React.createElement("div", null, "All Projects"),
                        React.createElement("span", { className: "text-xs text-gray-600" }, "(Uncheck to select specific projects)"))),
                !role.allProjects && (React.createElement(React.Fragment, null,
                    React.createElement(Dropdown, { search: true, className: "fluid", placeholder: "Select", selection: true, options: projectOptions, name: "projects", value: null, onChange: writeOption, id: "change-dropdown", selectOnBlur: false, selectOnNavigation: false }),
                    role.projects.size > 0 && (React.createElement("div", { className: "flex flex-row items-start flex-wrap mt-4" }, role.projects.map(function (p) { return (OptionLabel(projectsMap, p, onChangeProjects)); })))))),
            React.createElement("div", { className: "form-group flex flex-col" },
                React.createElement("label", null, 'Capability Access'),
                React.createElement(Dropdown, { search: true, className: "fluid", placeholder: "Select", selection: true, options: permissions, name: "permissions", value: null, onChange: writeOption, id: "change-dropdown", selectOnBlur: false, selectOnNavigation: false }),
                role.permissions.size > 0 && (React.createElement("div", { className: "flex flex-row items-start flex-wrap mt-4" }, role.permissions.map(function (p) { return (OptionLabel(permissionsMap, p, onChangePermissions)); }))))),
        React.createElement("div", { className: "flex items-center" },
            React.createElement("div", { className: "flex items-center mr-auto" },
                React.createElement(Button, { onClick: _save, disabled: !role.validate(), loading: saving, primary: true, marginRight: true }, role.exists() ? 'Update' : 'Add'),
                React.createElement(Button, { "data-hidden": !role.exists(), onClick: closeModal, outline: true }, 'Cancel')),
            role.exists() && (React.createElement("div", null,
                React.createElement(Button, { "data-hidden": !role.exists(), onClick: function () { return props.deleteHandler(role); }, hover: true, noPadding: true },
                    React.createElement(Icon, { name: "trash", size: "18" })))))));
};
export default connect(function (state) {
    var role = state.getIn(['roles', 'instance']);
    var projects = state.getIn(['site', 'list']);
    return {
        role: role,
        projectOptions: projects.map(function (p) { return ({
            key: p.get('id'),
            value: p.get('id'),
            text: p.get('name'),
            disabled: role.projects.includes(p.get('id')),
        }); }).toJS(),
        permissions: state.getIn(['roles', 'permissions'])
            .map(function (_a) {
            var text = _a.text, value = _a.value;
            return ({ text: text, value: value, disabled: role.permissions.includes(value) });
        }).toJS(),
        saving: state.getIn(['roles', 'saveRequest', 'loading']),
        projectsMap: projects.reduce(function (acc, p) {
            acc[p.get('id')] = p.get('name');
            return acc;
        }, {}),
    };
}, { edit: edit, save: save })(RoleForm);
function OptionLabel(nameMap, p, onChangeOption) {
    return React.createElement("div", { className: "px-2 py-1 rounded bg-gray-lightest mr-2 mb-2 border flex items-center justify-between" },
        React.createElement("div", null, nameMap[p]),
        React.createElement("div", { className: "cursor-pointer ml-2", onClick: function () { return onChangeOption(p); } },
            React.createElement(Icon, { name: "close", size: "12" })));
}
