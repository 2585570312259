import store from 'App/store';
import { update, getState } from '../../store';
import AnnotationCanvas from './AnnotationCanvas';
import MStreamReader from '../messages/MStreamReader';
import JSONRawMessageReader from '../messages/JSONRawMessageReader';
export var CallingState;
(function (CallingState) {
    CallingState[CallingState["NoCall"] = 0] = "NoCall";
    CallingState[CallingState["Connecting"] = 1] = "Connecting";
    CallingState[CallingState["Requesting"] = 2] = "Requesting";
    CallingState[CallingState["Reconnecting"] = 3] = "Reconnecting";
    CallingState[CallingState["OnCall"] = 4] = "OnCall";
})(CallingState || (CallingState = {}));
export var ConnectionStatus;
(function (ConnectionStatus) {
    ConnectionStatus[ConnectionStatus["Connecting"] = 0] = "Connecting";
    ConnectionStatus[ConnectionStatus["WaitingMessages"] = 1] = "WaitingMessages";
    ConnectionStatus[ConnectionStatus["Connected"] = 2] = "Connected";
    ConnectionStatus[ConnectionStatus["Inactive"] = 3] = "Inactive";
    ConnectionStatus[ConnectionStatus["Disconnected"] = 4] = "Disconnected";
    ConnectionStatus[ConnectionStatus["Error"] = 5] = "Error";
    ConnectionStatus[ConnectionStatus["Closed"] = 6] = "Closed";
})(ConnectionStatus || (ConnectionStatus = {}));
export var RemoteControlStatus;
(function (RemoteControlStatus) {
    RemoteControlStatus[RemoteControlStatus["Disabled"] = 0] = "Disabled";
    RemoteControlStatus[RemoteControlStatus["Requesting"] = 1] = "Requesting";
    RemoteControlStatus[RemoteControlStatus["Enabled"] = 2] = "Enabled";
})(RemoteControlStatus || (RemoteControlStatus = {}));
export function getStatusText(status) {
    switch (status) {
        case ConnectionStatus.Closed:
            return 'Closed...';
        case ConnectionStatus.Connecting:
            return "Connecting...";
        case ConnectionStatus.Connected:
            return "";
        case ConnectionStatus.Inactive:
            return "Client tab is inactive";
        case ConnectionStatus.Disconnected:
            return "Disconnected";
        case ConnectionStatus.Error:
            return "Something went wrong. Try to reload the page.";
        case ConnectionStatus.WaitingMessages:
            return "Connected. Waiting for the data... (The tab might be inactive)";
    }
}
export var INITIAL_STATE = {
    calling: CallingState.NoCall,
    peerConnectionStatus: ConnectionStatus.Connecting,
    remoteControl: RemoteControlStatus.Disabled,
};
var MAX_RECONNECTION_COUNT = 4;
var AssistManager = /** @class */ (function () {
    function AssistManager(session, md, config) {
        var _this = this;
        this.session = session;
        this.md = md;
        this.config = config;
        this.onVisChange = function () {
            var _a;
            var inactiveTimeout;
            if (document.hidden) {
                inactiveTimeout = setTimeout(function () {
                    var _a;
                    var state = getState();
                    if (document.hidden &&
                        (state.calling === CallingState.NoCall && state.remoteControl === RemoteControlStatus.Enabled)) {
                        (_a = _this.socket) === null || _a === void 0 ? void 0 : _a.close();
                    }
                }, 30000);
            }
            else {
                inactiveTimeout && clearTimeout(inactiveTimeout);
                (_a = _this.socket) === null || _a === void 0 ? void 0 : _a.open();
            }
        };
        this.socket = null;
        /* ==== Remote Control ==== */
        this.onMouseMove = function (e) {
            if (!_this.socket) {
                return;
            }
            var data = _this.md.getInternalCoordinates(e);
            _this.socket.emit("move", [data.x, data.y]);
        };
        this.onWheel = function (e) {
            e.preventDefault();
            if (!_this.socket) {
                return;
            }
            //throttling makes movements less smooth, so it is omitted
            //this.onMouseMove(e)
            _this.socket.emit("scroll", [e.deltaX, e.deltaY]);
        };
        this.onMouseClick = function (e) {
            if (!_this.socket) {
                return;
            }
            var data = _this.md.getInternalViewportCoordinates(e);
            // const el = this.md.getElementFromPoint(e); // requires requestiong node_id from domManager
            var el = _this.md.getElementFromInternalPoint(data);
            if (el instanceof HTMLElement) {
                el.focus();
                el.oninput = function (e) {
                    if (el instanceof HTMLTextAreaElement
                        || el instanceof HTMLInputElement) {
                        _this.socket && _this.socket.emit("input", el.value);
                    }
                    else if (el.isContentEditable) {
                        _this.socket && _this.socket.emit("input", el.innerText);
                    }
                };
                //el.onkeydown = e => e.preventDefault()
            }
            _this.socket.emit("click", [data.x, data.y]);
        };
        this.requestReleaseRemoteControl = function () {
            if (!_this.socket) {
                return;
            }
            var remoteControl = getState().remoteControl;
            if (remoteControl === RemoteControlStatus.Requesting) {
                return;
            }
            if (remoteControl === RemoteControlStatus.Disabled) {
                update({ remoteControl: RemoteControlStatus.Requesting });
                _this.socket.emit("request_control");
                // setTimeout(() => {
                //   if (getState().remoteControl !== RemoteControlStatus.Requesting) { return }
                //   this.socket?.emit("release_control")
                //   update({ remoteControl: RemoteControlStatus.Disabled })
                // }, 8000)
            }
            else {
                _this.socket.emit("release_control");
                _this.toggleRemoteControl(false);
            }
        };
        /* ==== PeerJS Call ==== */
        this._peer = null;
        this.connectionAttempts = 0;
        this.callConnection = null;
        this.initiateCallEnd = function () {
            var _a;
            (_a = _this.socket) === null || _a === void 0 ? void 0 : _a.emit("call_end");
            _this.handleCallEnd();
        };
        this.onRemoteCallEnd = function () {
            if (getState().calling === CallingState.Requesting) {
                _this.callArgs && _this.callArgs.onReject();
                _this.callConnection && _this.callConnection.close();
                update({ calling: CallingState.NoCall });
                _this.callArgs = null;
            }
            else {
                _this.handleCallEnd();
            }
        };
        this.callArgs = null;
        this.annot = null;
        /* ==== Cleaning ==== */
        this.cleaned = false;
    }
    AssistManager.prototype.setStatus = function (status) {
        if (getState().peerConnectionStatus === ConnectionStatus.Disconnected &&
            status !== ConnectionStatus.Connected) {
            return;
        }
        if (status === ConnectionStatus.Connecting) {
            this.md.setMessagesLoading(true);
        }
        else {
            this.md.setMessagesLoading(false);
        }
        if (status === ConnectionStatus.Connected) {
            this.md.display(true);
        }
        else {
            this.md.display(false);
        }
        update({ peerConnectionStatus: status });
    };
    Object.defineProperty(AssistManager.prototype, "peerID", {
        get: function () {
            return this.session.projectKey + "-" + this.session.sessionId;
        },
        enumerable: false,
        configurable: true
    });
    AssistManager.prototype.connect = function () {
        var _this = this;
        var jmr = new JSONRawMessageReader();
        var reader = new MStreamReader(jmr);
        var waitingForMessages = true;
        var showDisconnectTimeout;
        import('socket.io-client').then(function (_a) {
            var io = _a.default;
            if (_this.cleaned) {
                return;
            }
            if (_this.socket) {
                _this.socket.close();
            } // TODO: single socket connection
            // @ts-ignore
            var urlObject = new URL(window.ENV.API_EDP); // does it handle ssl automatically?
            // @ts-ignore WTF, socket.io ???
            var socket = _this.socket = io(urlObject.origin, {
                path: '/ws-assist/socket',
                query: {
                    peerId: _this.peerID,
                    identity: "agent",
                }
            });
            //socket.onAny((...args) => console.log(...args))
            socket.on("connect", function () {
                waitingForMessages = true;
                _this.setStatus(ConnectionStatus.WaitingMessages); // TODO: happens frequently on bad network
            });
            socket.on("disconnect", function () {
                _this.toggleRemoteControl(false);
                update({ calling: CallingState.NoCall });
            });
            socket.on('messages', function (messages) {
                //console.log(messages.filter(m => m._id === 41 || m._id === 44))
                showDisconnectTimeout && clearTimeout(showDisconnectTimeout);
                jmr.append(messages); // as RawMessage[]
                if (waitingForMessages) {
                    waitingForMessages = false; // TODO: more explicit
                    _this.setStatus(ConnectionStatus.Connected);
                    // Call State
                    if (getState().calling === CallingState.Reconnecting) {
                        _this._call(); // reconnecting call (todo improve code separation)
                    }
                }
                for (var msg = reader.readNext(); msg !== null; msg = reader.readNext()) {
                    //@ts-ignore
                    _this.md.distributeMessage(msg, msg._index);
                }
            });
            socket.on("control_granted", function (id) {
                _this.toggleRemoteControl(id === socket.id);
            });
            socket.on("control_rejected", function (id) {
                id === socket.id && _this.toggleRemoteControl(false);
            });
            socket.on('SESSION_DISCONNECTED', function (e) {
                waitingForMessages = true;
                showDisconnectTimeout = setTimeout(function () {
                    if (_this.cleaned) {
                        return;
                    }
                    _this.setStatus(ConnectionStatus.Disconnected);
                }, 30000);
                if (getState().remoteControl === RemoteControlStatus.Requesting) {
                    _this.toggleRemoteControl(false); // else its remaining
                }
                // Call State
                if (getState().calling === CallingState.OnCall) {
                    update({ calling: CallingState.Reconnecting });
                }
                else if (getState().calling === CallingState.Requesting) {
                    update({ calling: CallingState.NoCall });
                }
            });
            socket.on('error', function (e) {
                console.warn("Socket error: ", e);
                _this.setStatus(ConnectionStatus.Error);
                _this.toggleRemoteControl(false);
            });
            socket.on('call_end', _this.onRemoteCallEnd);
            document.addEventListener('visibilitychange', _this.onVisChange);
        });
    };
    AssistManager.prototype.toggleRemoteControl = function (newState) {
        if (newState) {
            this.md.overlay.addEventListener("mousemove", this.onMouseMove);
            this.md.overlay.addEventListener("click", this.onMouseClick);
            this.md.overlay.addEventListener("wheel", this.onWheel);
            update({ remoteControl: RemoteControlStatus.Enabled });
        }
        else {
            this.md.overlay.removeEventListener("mousemove", this.onMouseMove);
            this.md.overlay.removeEventListener("click", this.onMouseClick);
            this.md.overlay.removeEventListener("wheel", this.onWheel);
            update({ remoteControl: RemoteControlStatus.Disabled });
        }
    };
    AssistManager.prototype.getPeer = function () {
        var _this = this;
        if (this._peer && !this._peer.disconnected) {
            return Promise.resolve(this._peer);
        }
        // @ts-ignore
        var urlObject = new URL(window.ENV.API_EDP);
        return import('peerjs').then(function (_a) {
            var Peer = _a.default;
            if (_this.cleaned) {
                return Promise.reject("Already cleaned");
            }
            var peerOpts = {
                host: urlObject.hostname,
                path: '/assist',
                port: urlObject.port === "" ? (location.protocol === 'https:' ? 443 : 80) : parseInt(urlObject.port),
            };
            if (_this.config) {
                peerOpts['config'] = {
                    iceServers: _this.config,
                    sdpSemantics: 'unified-plan',
                    iceTransportPolicy: 'relay',
                };
            }
            var peer = _this._peer = new Peer(peerOpts);
            peer.on('error', function (e) {
                if (e.type === 'disconnected') {
                    return peer.reconnect();
                }
                else if (e.type !== 'peer-unavailable') {
                    console.error("PeerJS error (on peer). Type " + e.type, e);
                }
                //call-reconnection connected
                //       if (['peer-unavailable', 'network', 'webrtc'].includes(e.type)) {
                // this.setStatus(this.connectionAttempts++ < MAX_RECONNECTION_COUNT 
                //   ? ConnectionStatus.Connecting
                //   : ConnectionStatus.Disconnected);
                // Reconnect...
            });
            return new Promise(function (resolve) {
                peer.on("open", function () { return resolve(peer); });
            });
        });
    };
    AssistManager.prototype.handleCallEnd = function () {
        var _a;
        this.callArgs && this.callArgs.onCallEnd();
        this.callConnection && this.callConnection.close();
        update({ calling: CallingState.NoCall });
        this.callArgs = null;
        (_a = this.annot) === null || _a === void 0 ? void 0 : _a.remove();
        this.annot = null;
    };
    AssistManager.prototype.call = function (localStream, onStream, onCallEnd, onReject, onError) {
        this.callArgs = {
            localStream: localStream,
            onStream: onStream,
            onCallEnd: onCallEnd,
            onReject: onReject,
            onError: onError,
        };
        this._call();
        return {
            end: this.initiateCallEnd,
        };
    };
    AssistManager.prototype._call = function () {
        var _this = this;
        if (![CallingState.NoCall, CallingState.Reconnecting].includes(getState().calling)) {
            return;
        }
        update({ calling: CallingState.Connecting });
        this.getPeer().then(function (peer) {
            if (!_this.callArgs) {
                return console.log("No call Args. Must not happen.");
            }
            update({ calling: CallingState.Requesting });
            // TODO: in a proper way
            _this.socket && _this.socket.emit("_agent_name", store.getState().getIn(['user', 'account', 'name']));
            var call = _this.callConnection = peer.call(_this.peerID, _this.callArgs.localStream.stream);
            _this.callArgs.localStream.onVideoTrack(function (vTrack) {
                var sender = call.peerConnection.getSenders().find(function (s) { var _a; return ((_a = s.track) === null || _a === void 0 ? void 0 : _a.kind) === "video"; });
                if (!sender) {
                    console.warn("No video sender found");
                    return;
                }
                //logger.log("sender found:", sender)
                sender.replaceTrack(vTrack);
            });
            call.on('stream', function (stream) {
                update({ calling: CallingState.OnCall });
                _this.callArgs && _this.callArgs.onStream(stream);
                if (!_this.annot) {
                    var annot_1 = _this.annot = new AnnotationCanvas();
                    annot_1.mount(_this.md.overlay);
                    annot_1.canvas.addEventListener("mousedown", function (e) {
                        if (!_this.socket) {
                            return;
                        }
                        var data = _this.md.getInternalViewportCoordinates(e);
                        annot_1.start([data.x, data.y]);
                        _this.socket.emit("startAnnotation", [data.x, data.y]);
                    });
                    annot_1.canvas.addEventListener("mouseleave", function () {
                        if (!_this.socket) {
                            return;
                        }
                        annot_1.stop();
                        _this.socket.emit("stopAnnotation");
                    });
                    annot_1.canvas.addEventListener("mouseup", function () {
                        if (!_this.socket) {
                            return;
                        }
                        annot_1.stop();
                        _this.socket.emit("stopAnnotation");
                    });
                    annot_1.canvas.addEventListener("mousemove", function (e) {
                        if (!_this.socket || !annot_1.isPainting()) {
                            return;
                        }
                        var data = _this.md.getInternalViewportCoordinates(e);
                        annot_1.move([data.x, data.y]);
                        _this.socket.emit("moveAnnotation", [data.x, data.y]);
                    });
                }
            });
            //call.peerConnection.addEventListener("track", e => console.log('newtrack',e.track))
            call.on("close", _this.onRemoteCallEnd);
            call.on("error", function (e) {
                console.error("PeerJS error (on call):", e);
                _this.initiateCallEnd();
                _this.callArgs && _this.callArgs.onError && _this.callArgs.onError();
            });
        });
    };
    AssistManager.prototype.clear = function () {
        this.cleaned = true; // sometimes cleaned before modules loaded
        this.initiateCallEnd();
        if (this._peer) {
            console.log("destroying peer...");
            var peer = this._peer; // otherwise it calls reconnection on data chan close
            this._peer = null;
            peer.disconnect();
            peer.destroy();
        }
        if (this.socket) {
            this.socket.close();
            document.removeEventListener('visibilitychange', this.onVisChange);
        }
        if (this.annot) {
            this.annot.remove();
            this.annot = null;
        }
    };
    return AssistManager;
}());
export default AssistManager;
